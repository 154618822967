@import "~component-library/dist/styles/constants.scss";

@keyframes bloopIn {
  0% {
    transform: scale(0);
    max-height: 0;
  }
  25% {
    max-height: 15rem;
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bloopOut {
  0% {
    transform: scale(1);
    max-height: 15rem;
  }
  75% {
    transform: scale(0);
    max-height: 15rem;
  }
  100% {
    max-height: 0;
    transform: scale(0);
  }
}

.wrapper, .container, .text_wrapper {
  display: flex;
}

.wrapper {
  flex-direction: row;
  border-left: 0.8rem solid;
  border-radius: 0.4rem;
  width: 100%;
  margin-bottom: 1.6rem;
  justify-content: space-between;
}

.animate {
  animation: bloopIn .4s ease-in-out 0s 1;
  transform-origin: top center;
  &.leaving {
    animation: bloopOut .4s ease-in-out 0s 1 forwards;
  }
}

.container {
  padding: 1.6rem;
}

.text_wrapper {
  flex-direction: column;
  margin-left: 1.6rem;
  font-size: 1.6rem;
}

.center_wrapper {
  align-items: center;
  font-size: 1.4rem;
}

.link_text {
  cursor: pointer;
  display: inline-flex;
  text-decoration: none!important;
  white-space: nowrap;
  align-items: center;
  margin-left: 0.8rem;
}

.shadow {
    box-shadow: $shadow;
}