@import "~component-library/dist/styles/constants.scss";

.cancelButton {
  margin-top: 0.8rem;
  margin-right: 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
  background-color: $gray09;
}

.headerText {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;

  svg {
    margin-right: 0.8rem;
  }
}

.formRow {
  margin-bottom: 4.8rem;
}

.formRowHalf {
  margin-bottom: 2.4rem;
}

.modalBody {
  padding: 2.4rem;
}

.submitButton {
  margin-top: 0;
  font-weight: bold;
}

.taskTitle {
  margin-top: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: $darkBlue11;
}

.taskDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  border-radius: 0.4rem;
  padding: 1rem 1.6rem;
  color: $gray02;
  background-color: $blue06;

  span {
    font-weight: 600;
  }
}

.bidDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  border-radius: 0.4rem;
  padding: 1rem 1.6rem;
  color: $gray02;
  background-color: $gray09;

  span {
    margin-left: 1.6rem;
    font-weight: 600;
  }
}