@import "~component-library/dist/styles/constants.scss";

.moduleHeader {
  width: 100%;
  height: 4.2rem;
  background-color: $navyDark;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    margin-top: 0.6rem;
    font-size: 1.8rem !important;
  }
}
.cancelBtn {
  background-color: $gray03;
  font-size: 1.36rem;
}
.submitBtn {
  background-color: $orange;
  font-size: 1.36rem;
}
