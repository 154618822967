@import "~component-library/dist/styles/constants.scss";

.gridWrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.gridWrapCenter {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gridWrapInner {
  flex: 1;
  display: flex;
}

.tableWrapper {
  flex-grow: 1;
  min-height: 51.4rem;
}

.searchFilter {
  width: 32rem;
  margin-bottom: 1.2rem;
}

.smallWrap {
  width: 120rem;
}