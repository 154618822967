@import "~component-library/dist/styles/constants.scss";

.todosStatus {
  width: 5.4rem;
  border: 1px solid $gray05;
  background-color: #fff;
  border-radius: 0.4rem;
  text-align: center;
  transform: translateX(calc(-50% + 0.4rem));
  position: absolute;
  bottom: 1.2rem;
  .todoStatusText {
    z-index: 2;
    position: relative;
  }
  &::after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    left: calc(50% - 1.4rem);
    bottom: -1.4rem;
    border-left: 1.5rem solid transparent;
    border-right: 1.4rem solid transparent;
    border-top: 1.4rem solid $gray05;
  }
  &::before {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    left: calc(50% - 1.4rem);
    bottom: -1.3rem;
    border-left: 1.4rem solid transparent;
    border-right: 1.4rem solid transparent;
    border-top: 1.4rem solid #fff;
    z-index: 1;
  }
}

.statusCircle {
  z-index: 100;
  position: relative;
}