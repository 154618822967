@import "~component-library/dist/styles/constants.scss";

.rowWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;
  height: 3.6rem;
  border-top: .1rem solid $gray06;
}
