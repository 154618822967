@import "~component-library/dist/styles/constants.scss";
.firstColumn {
  color: $boomerangBlue;
  font-weight: bold;
  width: 19.3rem;
}

.timeEntry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.1rem;
  padding: 1.2rem 0.8rem;
  background-color: $gray08;
  border-radius: 0.4rem;
  text-decoration: none;

  a {
    text-decoration: none;
  }
}

.select {
  max-width: 27.5rem;
}