@import "~component-library/dist/styles/constants.scss";

.entryGrabby {
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  width: 1.9rem;
  left: 0;
  height: 4.8rem;
  margin-right: 0.9rem;
}

.grabby {
  display: flex;
  background-color: $gray06;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.grabbyRow {
  display: flex;
  width: 0.9rem;
  height: 0.5rem;
  justify-content: space-between;
  flex-direction: row;
}
.grabbyDot {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 1.5rem;
  background-color: $gray05;
}