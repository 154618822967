@import "~component-library/dist/styles/constants.scss";

.header {
  padding: 1rem 1rem;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  background-color: $gray06;
}

.body {
  width: 100%;
  flex: 1;
  max-height: 60rem;
  padding: 1.2rem 2.4rem 2.4rem 2.4rem;
}

.dateDay {
  display: flex;
  align-items: center;
  color: $darkBlue11;
  font-weight: 600;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.title {
  color: $boomerangBlue;
  font-weight: 600;
}

.note {
  margin-bottom: 1rem;
}

.notesBody {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0.8rem;
  margin: 1rem 1rem 0;
}

.noteName {
  margin-right: 0.5rem;
  color: $darkBlue11;
}

.time {
  color: $gray03;
  font-style: italic;
}

.icon {
  color: $gray03;
}

.noteContent {
  font-size: 1.6rem;
  color: $gray03;
  background-color: $gray09;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  margin: 1rem 1rem 1rem 0;
}

.noteContent p {
  display: block;
  width: 60.6rem;
  overflow-wrap: break-word;
}

.deletedTimesheet {
  color: $gray03;
  font-style: italic;
  border-left: 2px solid $redOrange;
  align-items: center;
  display: flex;
  background-color: $gray09;
  border-radius: 2px;
}

.noteTitle {
  color: $redOrange;
  font-weight: 600;
}

.iconDeleted {
  color: $redOrange;
  margin: 0 0.5rem;
}



.noteCount {
  color: $gray03;
}

.note {
  border: 1px solid $gray06;
  border-radius: 0.8rem;
}

.imageGroupWrapper {
  width: 11rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: 1rem;
  cursor: pointer;
}

.imageWrapper {
  position: absolute;
  max-height: 7.5rem;
  overflow: hidden;
  border-radius: 0.4rem;
}

.largeImg {
  width: 9.8rem;
  border-radius: 0.4rem;
  margin: 0.2rem;
}

.smallImg {
  width: 4.4rem;
  border-radius: 0.4rem;
  margin: 0.2rem;
}

.taskNameWrap {
  max-width: 30rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hasImages {
  width: calc(100% - 11rem);
}

.noImages {
  width: 100%;
}