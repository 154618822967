@import "~component-library/dist/styles/constants.scss";
a {
  text-decoration: none;
  color: unset;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid $gray06;
  background-color: $white;
  padding: 0rem 2rem;
  width: 100%;
  margin-bottom: 4rem;
  margin-right: 1rem;
}

@media screen and (min-width: 768px) {
  .card {
    width: 49%;
  }
}

@media screen and (min-width: 1280px) {
  .card {
    width: 32%;
  }
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
}

.favoriteButton {
  padding: 1rem;
}

.projectNumber {
  color: $gray03;
}

.projectName {
  font-weight: 600;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardTitle {
  display: flex;
  flex-direction: row;
  width: 32rem;
  align-self: center;
  gap: 0.5rem;
}

.cardBody {
}

.cardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
}

.userCount {
  color: $white;
  padding: 0 0.75rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2rem;
  font-size: small;
  line-height: 1.75rem;
}

.onSite {
  background-color: $navy;
}

.noneOnSite {
  background-color: $white;
  border: 1px solid $checkboxGray;
  color: $checkboxGray;
}

.cardImage {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 3/1;
} 

.projectCategory {
  color: $white;
  border-radius: 2rem;
  padding: 0.125rem 1rem;
  font-size: x-small;
  line-height: 1.5rem;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.pw { 
  background-color: $red;
  color: $white;
  border-radius: 2rem;
  padding: 0.125rem 1rem;
  font-size: x-small;
  line-height: 1.5rem;
  margin-right: 1rem;
}

@media screen and (max-width: 1440px) {
  .cardTitle {
    width: 26rem;
  }
}

@media screen and (max-width: 1280px) {
  .cardTitle {
    width: 28rem;
  }
}

@media screen and (max-width: 768px) {
  .cardTitle {
    width: 100%;
  }
}