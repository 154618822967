@import "~component-library/dist/styles/constants.scss";

.priority {
  text-transform: uppercase;
  line-height: 1.6rem;
  border-radius: 0.8rem;
  padding: 0 0.8rem;
  display: inline-block;
  color: #fff;
  font-size: 1.2rem;
}
.med {
  background-color: $yellowOrange;
}
.high {
  background-color: $redOrange;
}
.low {
  background-color: $paleGreen;
}
.overdue {
  color: $redOrange;
  font-weight: bold;
}

.fakeDropdown {
  position: relative;
  width: 15rem;
  border: 1px solid $gray05;
  border-radius: 0.5rem;
  color: $grayTableHeader;
  font-size: 1.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popoverWrapper {
  margin-top: -1.6rem;
  overflow-y: auto;
  max-height: 10rem;
}
