@import "~component-library/dist/styles/constants.scss";
.header {
  display: flex;
  align-items: center;
  padding: 0.5rem 2.5rem;
  height: 6.4rem;
  margin-bottom: 2.2rem;
  color: $darkBlue11;
  background-color: $gray09;
  border-radius: 0;
}

.titleText,
.albumName {
  font-size: 2rem;
  color: $darkBlue11;
  font-weight: 600;
  line-height: 1;
}

.backdrop {
  background-color: $darkBlue11;
  opacity: .8 !important;
}

.modalContent {
  border-top: 0;
  border-radius: 0.4rem;
  padding: 0;
  height: calc(100vh - 9.6rem);
  display: flex;
  flex-direction: column;
}

.dialogWrap {
  width: 90%;
  max-width: 120rem;
}

.modalBody {
  flex-shrink: 1;
  flex-grow: 0;
  overflow: hidden;
  padding: 0;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.footer {
  height: 8rem;
  border-top: none;
  background-color: $gray09;
}

.sectionTitle {
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-size: 2rem;
  color: $darkBlue11;
}

.viewToggle {
  display: flex;
  align-items: center;
  margin-left: 1.6rem;

  button:first-child {
    margin-right: 0.8rem;
  }

  button {
    width: 3.2rem;
    height: 3.2rem;
    border: 1px solid $gray05;
  }
}

.active {
  background-color: $boomerangBlue;
}

.icon {
  margin-right: 0.8rem;
}

.photoCount {
  margin-left: 1.7rem;
  color: $gray03;
  font-size: 1.6rem;
  line-height: 1;
}

.dimmed {
  position: relative;
}

.dimmed:after {
  content: " ";
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 10, 15, 0.1);
}