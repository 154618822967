@import "~component-library/dist/styles/constants.scss";

.subtext {
  font-size: 1.4rem;
  color: $gray03;
  line-height: 1;
}
.subtaskName {
  font-weight: 600;
  font-size: 1.6rem;
  color: $boomerangBlue;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20rem;
}
.costCode {
  font-size: 1.6rem;
  color: $darkBlue10;
  width: 9rem;
  padding-left: 1.6rem;
  font-weight: 600;
  align-self: flex-start;
}
.overUnder {
  font-size: 1.6rem;
  color: $green;
  width: 15rem;
  display: flex;
  justify-content: flex-end;
  &.zero {
    color: $boomerangBlue;
  }
  &.overage {
    color: $redOrange;
  }
}
.overUnderValue {
  font-weight: 700;
}
.overUnderPercentage {
  font-weight: 400;
}

.percentage {
  font-weight: 700;
}
.reported {
  width: 15rem;
  color: $darkBlue10;
  line-height: 1.6;
  font-weight: 700;
}
.estimate {
  font-size: 1.6rem;
  font-weight: 700;
  color: $darkBlue10;
}
.remaining {
  color: $green;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
}
.warn {
  color: $redOrange;
}

.percWrap {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.6rem;
  font-weight: 700;
  width: 7.5rem;
  color: $darkBlue10;
}
.colHeader {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  height: 3.2rem;
}
.taskContentWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1rem;

  &.taskSmallWrapper {
    flex: 0;
  }
}