@import "~component-library/dist/styles/constants.scss";

.libraryGrid {
  display: grid;
  column-gap: 0.2rem;
  row-gap: 0.2rem;
  margin-bottom: 2rem;

  .imgWrapper {
    position: relative;
    width: 100%;
    background-color: $gray06;

    &:hover,
    &:focus {
      .overlay {
        display: flex;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.hover {
  &:hover {
    background-color: $boomerangBlue;
  }
  img {
    &:hover {
      opacity: 0.66;
    }
  }
}

.overlay {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 113, 206, 0.75);
  gap: 1.5rem;
}

.overlayAlbum {
  padding-bottom: 5.4rem;
}

.largeGrid {
  grid-template-columns: repeat(auto-fill, 18.9rem);

  .imgWrapper {
    height: 18.9rem;
  }
}

.mediumGrid {
  grid-template-columns: repeat(auto-fill, 15.7rem);

  .imgWrapper {
    height: 15.7rem;
  }
}

.smallGrid {
  grid-template-columns: repeat(auto-fill, 11.8rem);

  .imgWrapper {
    height: 11.8rem;
  }
}

.selectedIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 1.2rem;
  left: 1.2rem;
  background-color: $white;
  width: 3.3rem;
  height: 3.3rem;
  border: 1px solid $gray05;
  border-radius: 50%;

  &.active {
    background-color: $boomerangBlue;
    border: 2px solid $white;
  }

  &.isAlbum {
    bottom: 6.4rem;
  }
}

.selectedOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $boomerangBlue;
  opacity: 0.66;
}

.pinIcon {
  width: 2rem;
  height: 2rem;
  color: $white;
}

.hoverIcon {
  cursor: pointer;
  padding: 0.5rem;
}

.name {
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 600;
  color: $darkBlue11;
}

.count {
  color: $gray03;
  line-height: .8;
}

.footerTextBox {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5.4rem;
  background-color: $white;
  padding: 0 1rem;
}

.sectionTitle {
  display: flex;
  align-items: start;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-size: 1.6rem;
  color: $darkBlue11;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 14.4rem;
}
