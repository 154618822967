@import "~component-library/dist/styles/constants.scss";
.wrapper {
  margin-left: 3rem;
}

.code {
  margin-right: 2rem;
  font-size: 1.8rem;
  color: $darkBlue10;
}

.bidItem {
  font-size: 1.8rem;
  font-weight: 600;
  color: $darkBlue10;
}

.taskName {
  font-weight: 600;
  font-size: 2rem;
  color: $boomerangBlue;
}

.date {
  font-size: 1.4rem;
  font-style: italic;
  color: $gray03;
}