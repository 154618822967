@import "~component-library/dist/styles/constants.scss";

.section {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  min-width: 20%;
  max-width: 50%;

  @media print {
    break-inside: avoid;
  }
}