@import "~component-library/dist/styles/constants.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.pinIcon {
  width: 1.6rem;
  height: 1.6rem;
  color: $boomerangBlue;
}

.selectText {
  font-size: 1.6rem;
  margin-left: 1rem;
  margin-top: .2rem;
  color: $darkBlue11;
}
