@import "~component-library/dist/styles/constants.scss";

.inlineTabs {
  border-radius: 0;
  color: $gray02 !important;
  font-size: 1.6rem;
  padding-left: 0;
  padding-right: 0;
  margin-right: 4rem;
  &.active {
    border-bottom: 0.2rem solid $boomerangBlue;
    color: $boomerangBlue !important;
  }
  &:hover, &:active, &:focus {
    text-decoration: none;
    box-shadow: none;
  }
}

.phone {
  background-color: $blueTenPercent;
  font-size: 1.1rem;
  color: $boomerangBlue;
  border-radius: 0.8rem;
  height: 1.6rem;
  padding: 0 0.6rem;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
}

.phoneIcon {
  margin-right: 0.2rem
}

.header {
  border-bottom: 1px solid $gray05;
}

.categoryButton {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.6rem;
  height: 3.2rem;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  background-color: transparent;
  border-color: $gray05;
  color: $gray03;
  text-transform: capitalize;
  &:active, &:hover, &:focus {
    background-color: transparent;
    border-color: $gray05;
    box-shadow: none;
    color: $gray03;
  }
  &.catActive {
    background-color: $blueTenPercent;
    border-width: 0;
    height: 3.4rem;
    color: $boomerangBlue;
    &:active, &:hover, &:focus {
      background-color: $blueTenPercent;
      border-color: $blueTenPercent;
      box-shadow: none;
      color: $boomerangBlue;
    }
  }

  .categoryFilters {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: '100%';
  }
}

.close {
  margin-left: 1rem;
}
.contactSearchButton {
  align-self: flex-start;
}
.linkNoDecor {
  color: $boomerangBlue
}
.linkNoDecor:hover {
  text-decoration: none;
  cursor: pointer;
}