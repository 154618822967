@import "~component-library/dist/styles/constants.scss";

.detailsBar {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  padding-bottom: 0.8rem;
  margin-top: 3rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.label {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: $gray03;
  text-align: right;
}

.value {
  font-weight: 600;
  color: $iGiveUpGray;
  max-width: 20rem;
  text-align: right;
}

.projectName {
  display: flex;
  color: $gray02;
  font-weight: 600;
}

.detailWrap {
  margin-right: 3rem;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.detailColumn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex: 1;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
    justify-content: space-between;
  }
}

.rightColumn {
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
}