@import "~component-library/dist/styles/constants.scss";

.mainText {
  color: $gray02;
}

.headerText {
  font-size: $xl;
  margin-top: 1.8rem;
  color: $gray02;
  font-weight: 600;
}

.btn {
  font-size: 1.6rem;
  padding: 1.2rem;
  font-weight: 600;
  border: none;
}

.btnAdjust {
  margin-right: 1.2rem;
  margin-top: 0.8rem;
}

.btnGrey {
  background-color: rgb(190, 190, 190);
  color: $white;
}

.btnText {
  color: $white;
}

.btnText:hover {
  text-decoration: none;
  color: $white;
}
