@import "~component-library/dist/styles/constants.scss";

.link {
  color: $boomerangBlue;
  font-weight: 600;
}

.linkLight {
  color: $boomerangBlue;
  cursor: pointer;
}