@import "~component-library/dist/styles/constants.scss";

.controlBar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1rem;
  z-index: 100;
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
}

.dateSelWrapper {
  display: flex;
  align-items: center;
}