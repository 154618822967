@import "~component-library/dist/styles/constants.scss";

.sectionTitle {
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: $darkBlue11;
}

.quickLinkGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}

.gridBlock {
  border-radius: 0.8rem;
  border: 1px solid $gray06;

  header {
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    padding: 0.5rem 1rem;
    color: $white;
    background-color: $boomerangBlue;
    font-size: 1.8rem;
    font-weight: 600;
  }
}

.blockContent {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}