@import "~component-library/dist/styles/constants.scss";
.subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2.5rem;
  background-color: $gray08;
}

.subheadTitle {
  font-weight: 600;
  color: $darkBlue11;
}

.subheaderDate{
  display: flex;
  align-items: center;
  color: $darkBlue11;
  font-weight: 600;
}

.subDetails {
  font-size: 1.8rem;
  color: $darkBlue11;
  font-weight: 600;
  padding: 1rem 2.5rem;
  line-height: 1.8rem;
}

.titleText {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 600;

  &.small {
    display: block;
    width: 100%;
    flex: 1;
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.6rem;
    font-weight: 600;
  }
}

.sectionTitle {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.8rem;
  color: $darkBlue11;
  padding: 1rem 0;
}

.modalContent {
  border-top: 0;
  border-radius: 0.4rem;
  padding: 0;
  height: calc(100vh - 15rem);
  display: flex;
  flex-direction: column;
}

.body {
  padding: 1.5rem 3rem;
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
}

.photoGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    height: auto;
    min-width: 45%;
    max-width: 45%;
    border-radius: 1.2rem;
    margin: 1rem;
    background-color: $grayConflict;
  }
}

.satisfactory {
  color: $green08;
  font-weight: 600;
  line-height: 1.8rem;
  font-size: 2.4rem;
  text-transform: uppercase;
}

.warning {
  color: $red02;
  font-weight: 600;
  line-height: 1.8rem;
  font-size: 2.4rem;
  text-transform: uppercase;
}

.label {
  width: 10rem;
  padding-right: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 3.2rem;
  color: $gray03;
  text-transform: uppercase;
  text-align: right;
}

.value {
  border-left: 1px solid $gray06;
  padding-left: 1rem;
  font-size: 1.6rem;
  color: $gray02;
  line-height: 3.2rem;
}

.commentText {
  margin-bottom: 1.5rem;
  color: $gray02;
  line-height: 2rem;
}

.note {
  font-weight: 400;
  color: $grayConflict;
}

.detailsDate {
  font-weight: 600;
  font-size: 1.6rem;
  color: $grayConflict;
}

.defect {
  color: $red02;
}