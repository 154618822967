@import "~component-library/dist/styles/constants.scss";

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-items: right;
  gap: 0.5rem;
}

.buttonActive {
  background-color: $boomerangBlue;
  color: $white;
  border: 1px solid $boomerangBlue;
  border-radius: 0.5rem;
}

.button {
  background-color: $white;
  color: $gray03;
  border: 1px solid $gray03;
  border-radius: 0.5rem;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.results {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.searchFilter {
  width: 30rem;
  margin-bottom: 1.2rem;
  align-self: flex-end;
}