@import "~component-library/dist/styles/constants.scss";

.categoriesPopup {
  display: flex;
  flex-direction: column;
  justify-items: right;
  gap: 0.5rem;

  div {
    width: fit-content;
    align-self: flex-end;
  }
}

.category_pill {
  background-color: #a7a7a7;
}

.moreLink {
  color: #a7a7a7;
  font-size: 1.2rem;
  margin-left: 0.4rem;
  cursor: pointer;
}
