@import "~component-library/dist/styles/constants.scss";

.tableWrapper {
  margin-top: 6rem;
}

.tableTitle {
  color: $gray02;
  font-weight: 600;
  font-size: 2rem;
}