@import "~component-library/dist/styles/constants.scss";

.table {
  text-align: left;
  width: 100%;
  table-layout: auto;
  th {
    border-top: none !important;
  }
  td {
    padding: 0.5rem 1rem;
    border-top: none !important;
    font-size: 1.4rem;
    align-items: center;
    vertical-align: middle;
    svg {
      margin-right: 0.5rem;
    }
  }

  tr:not(:last-child) {
    border-bottom: 0.5px solid $gray04;
  }
  .relocate {
    color: $gray04;
    text-transform: capitalize;
  }
}

.number {
  color: $nearBlack;
  font-weight: bold;
  text-decoration: underline;
}

.tableHead {
  th {
    font-weight: 400;
    font-size: 1.2rem;
    color: $gray04;
    text-transform: uppercase;
    border-bottom: 0.5px solid $gray04;
    padding-bottom: 0.5rem;
  }
}

.sbBadge {
  font-size: 1.2rem;
  padding: 0 0.5rem;
  border-radius: 1.5rem;
  color: #ffffff;
  background-color: $yellowOrange;
}

.dsBadge {
  font-size: 1.2rem;
  padding: 0 0.5rem;
  border-radius: 1.5rem;
  color: #ffffff;
  background-color: #ffc866;
}

.badge {
  margin: 0 0.2rem;
}

.locateNumberInput::-webkit-outer-spin-button,
.locateNumberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.locateNumberInput[type="number"] {
  -moz-appearance: textfield;
}

.rowHistoryToggleButton {
  background-color: transparent;
  border-color: transparent;
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  border: 0;
  &:active,
  &:hover,
  &:focus {
    background-color: transparent;
    border-color: $gray05;
    box-shadow: none;
    color: $gray03;
  }
}

.sectionToggleButton {
  background-color: transparent;
  border-color: transparent;
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  &:active,
  &:hover,
  &:focus {
    background-color: transparent;
    border-color: $gray05;
    box-shadow: none;
    color: $gray03;
  }
}

.descColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 48rem;
  font-style: italic;
}

.modalPermitDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
