@import "~component-library/dist/styles/constants.scss";

.table {
  tr, td, th {
    border: none;
  }

  th {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.4rem;
    color: $gray03;
  }
}

.warning {
  color: $red;
}