@import "~component-library/dist/styles/constants.scss";

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}

.subHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  background-color: $gray09;
}

.reportDay {
  margin-bottom: 2rem;

  @media print {
    break-inside: avoid;
  }
}

.navIcon {
  color: $white;

  @media print {
    color: $gray03;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  flex: 1;
}

.removeButton {
  padding: 1rem;
  position: absolute;
}
