@import "~component-library/dist/styles/constants.scss";

.taskPopover {
  max-height: 35rem;
  min-width: 25rem;
  overflow: auto;
}

.topLoadWrap {
  top: 0,
}

.tasksWrapper {
  padding-left: 0.5rem;
}

.header {
  margin-bottom: 0.9rem;
  padding-bottom: 0.9rem;
  border-bottom: 1px solid $gray05;
}

.taskOption {
  display: block;
  width: 100%;

  &:hover,
  &:focus {
    border-radius: 0.2rem;
    background-color: $gray08;
  }
}