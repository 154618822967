@import "~component-library/dist/styles/constants.scss";

.hourInput {
  width: 5rem;
  padding: 0;
}

.taskName {
  max-width: 50rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.detailsRow {
  border-bottom: 1px solid $gray06;
}