@import "~component-library/dist/styles/constants.scss";

.icon {
  width: 1.6rem;
  height: 1.6rem;
  color: $boomerangBlue;
}

.category_pill {
  background-color: #A7A7A7;
}

.lightText {
  color: #A7A7A7;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.selectedVendorsPopUp {
  min-width: 40rem;
  width: 40rem;
}

.selectedVendorsContent {
  max-height: 25rem;
  overflow-y: auto;
}

.selectedVendorsTitle {
  background-color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
}

.contact {
  .name {
    color: #4F4F4F;
    font-size: 1.6rem;
  }
  .contactPill {
    font-weight: bold;
    font-size: 1.1rem;
    color: $boomerangBlue;
  }
  .title {
    color: #717171;
    font-size: 1.2rem;
  }
}

.categoriesPopup {
  display: flex;
  flex-direction: column;
  justify-items: right;
  gap: 0.5rem;

  div {
    width: fit-content;
    align-self: flex-end;
  }
}
.moreLink {
  color: #A7A7A7;
  font-size: 1.2rem;
  margin-left: 0.4rem;
  cursor: pointer;
}

.contactName {
  cursor: pointer;
}

.contactNameOpen {
  text-decoration: underline;
  font-weight: 600;
}

.vendor {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 3rem);
}