@import "~component-library/dist/styles/constants.scss";

.selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 999rem;
  border: 2px solid $gray06;
  background: white;

  &.selectable {
    cursor: pointer;
  }

  &.selected {
    background: $boomerangBlue;
  }
}
