@import "~component-library/dist/styles/constants.scss";

.noResultsWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.grid {
  display: grid;
  width: 100%;
  column-gap: 0.4rem;
  row-gap: 0.4rem;
  grid-template-columns: repeat(5, 1fr);

  .placeholder {
    aspect-ratio: 1;
    background-color: $gray09;
  }
}

.title {
  font-weight: 600;
  font-size: 2.4rem;
  color: $darkBlue10;
}

.subtext {
  color: $gray03;
}

.overlay {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.uploadButton {
  padding: 0.8rem 1.6rem;
  border-radius: 0.8rem;
  margin: 2.6rem 1rem 0 1rem;
  font-size: 2rem;
  font-weight: 600;
}