@import "~component-library/dist/styles/constants.scss";

.submittal-row {
  .sub-cell {
    vertical-align: middle;
    padding: 0 1rem;
  }
  &:nth-of-type(odd) {
    background-color: $trBackground;
  }
}

.submittal-row-head {
  .sub-cell {
    padding: 0 1rem;
  }
}