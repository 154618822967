@import "~component-library/dist/styles/constants.scss";

.modalTitle {
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;
  margin-left: 0.8rem;
  margin-bottom: 0;
}

.scrollable {
  max-height: 50rem;
  overflow: auto;
}

.albumItemWrapper {
  background-color: $itsGrayHooray;
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
}

.albumItemText {
  color: $darkBlue11;
  font-size: 1.6rem;
}

.radio {
  appearance: none;
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid $gray05;
  border-radius: 0.2rem;
  outline: none;
  margin-right: 1.2rem;
  cursor: pointer;
}

.radio:checked {
  background-color: $boomerangBlue;
  border-color: $boomerangBlue;
}

.cancelButton {
  margin-top: 0.8rem;
  margin-right: 1.2rem;
}
