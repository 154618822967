@import "~component-library/dist/styles/constants.scss";

.sectionWrap {
  margin-top: 3.4rem;
  margin-bottom: 5.5rem;
}

.grid {
  margin-left: -0.8rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.cardWrapper {
  max-width: 25rem;
  margin: 0.8rem;
}

.keyRow {
  border-bottom: 1px solid $gray06;
}

.addButton {
  background-color: transparent;
  border: 0;
  font-size: 1.6rem;
  &:hover,
  &:focus {
    background-color: transparent;
    text-decoration: none;
  }
}
