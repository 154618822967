@import "~component-library/dist/styles/constants.scss";

.styleWrap {
  color: $gray02;
  margin-top: 1.2rem;
}

.heading, .heading18, .heading16 {
  margin-top: 1.2rem;
  font-weight: 600;
}
  .heading {
    font-size: 2rem;
  }
  .heading18 {
    font-size: 1.8rem;
  }
  .heading16 {
    font-size: 1.6rem;
  }

.tinyText {
  font-weight: 400;
  color: $grayConflict
}

.refreshBtn {
  height: 50%;
  align-self: center;
  margin-top: 1.6rem;
  margin-left: 0.6rem;
}

.rowFields {
  margin-right: 1.6rem;
}

.checkboxWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  
  > div {
    width: 23%;
  }
}

.widthAdjust {
  width: 22.4rem;
  margin-right: 1.5rem;
}

.moduleHeader {
  width: 100%;
  height: 4.2rem;
  background-color: $navyDark;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    margin-top: 0.6rem;
    font-size: 1.8rem !important;
  }
}
.cancelBtn {
  background-color: $gray03;
  font-size: 1.36rem;
}
.submitBtn {
  background-color: $orange;
  font-size: 1.36rem;
}
.columnWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
}
