@import "~component-library/dist/styles/constants.scss";

.title {
  color: $darkBlue11;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0;
}

.mobileContent {
  height: calc(100vh - 4rem);
  padding: 0 1.5rem;
  background-color: $itsGrayHooray;
}

.bodyWrapper {
  height: calc(100vh - 14rem);
  padding-bottom: 10rem;
}

.subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  width: 100%;
}

.actionRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  width: 100%;
  flex: 1;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
}

.dateWrapper {
  width: 100%;
  flex: 1;

  @media (min-width: 768px) {
    width: auto;
    flex: 0;
  }
}

.body {
  flex: 1;
  width: 100%;
  padding: 1.5rem 3rem;
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
}

.white {
  border: 1px solid $grayConflict;
  color: $gray04;
  background-color: $white;
}

.resultWrapper {
  padding: 1.8rem 3rem 0;
  border: 1px solid $gray05;
  border-radius: 0.4rem;
  flex: 1;
  width: 100%;
  background-color: $white;
  margin-bottom: 2rem;
}

.actionTitle {
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: 600;
  color: $darkBlue11;
}

.idBar {
  padding: 0 1rem;
  margin-bottom: 1rem;
  background-color: $gray08;
}

.listHeadline {
  font-weight: 600;
}

.listsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.driverName {
  color: $gray03;
}

.notesWrapper {
  width: 34rem;
}