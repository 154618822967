@import "~component-library/dist/styles/constants.scss";

.thumbnailContainer {
  position: relative;
  width: 8rem;
  height: 8rem;
  min-width: 8rem;
  min-height: 8rem;
  overflow: hidden;
  transition: all 50ms ease-in-out;

  &.selected {
    cursor: default;
    width: 10rem;
    height: 10rem;
    min-width: 10rem;
    min-height: 10rem;
    margin-left: -1rem;
    margin-top: -1rem;
    margin-right: -1rem;
    margin-bottom: -1rem;
    border: 3px solid white;
    z-index: 1;
  }

  &.unselected {
    cursor: pointer;
    &:hover {
      width: 9rem;
      height: 9rem;
      min-width: 9rem;
      min-height: 9rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
      margin-right: -0.5rem;
      margin-bottom: -0.5rem;
      z-index: 2;
    }
  }
}

.thumbnailContainerSmall {
  position: relative;
  width: 6rem;
  height: 6rem;
  min-width: 6rem;
  min-height: 6rem;
  overflow: hidden;
  transition: all 50ms ease-in-out;

  &.selected {
    cursor: default;
    width: 7.4rem;
    height: 7.4rem;
    min-width: 7.4rem;
    min-height: 7.4rem;
    margin-left: -0.7rem;
    margin-top: -0.7rem;
    margin-right: -0.7rem;
    margin-bottom: -0.7rem;
    border: 2px solid white;
    z-index: 1;
  }

  &.unselected {
    cursor: pointer;
    &:hover {
      width: 6.6rem;
      height: 6.6rem;
      min-width: 6.6rem;
      min-height: 6.6rem;
      margin-left: -0.3rem;
      margin-top: -0.3rem;
      margin-right: -0.3rem;
      margin-bottom: -0.3rem;
      z-index: 2;
    }
  }
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navArrow {
  padding: 0.5rem;
  cursor: pointer;
}

.navArrowSmall {
  padding: 0.3rem;
  cursor: pointer;
}

.albumIcon {
  margin: auto;
}

.albumIconBack {
  margin: auto;
  padding-top: 0.1rem;
  padding-left: 0.1rem;
}
