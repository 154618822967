@import "~component-library/dist/styles/constants.scss";

.close {
  font-size: 1.6rem;
  margin-right: 1.5rem;
}

.previousComments {
  max-height: 40rem;
  overflow: auto;
}

.textInput {
  width: 100%;
  padding: 0.5rem 1rem;
}

.textHeader {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  background-color: $gray09;
  margin-top: 1rem;
}

.taskName {
  max-width: 50rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  color: $darkBlue11;
}

.commentDisplay {
  border-bottom: 1px solid $gray06;

  &:last-child {
    border-bottom: 0;
  }
}

.errorText {
  font-size: 1.6rem;
  color: $red;
}

.photosButton {
  position: relative;
  overflow: hidden;
  padding: 0;
}

.previousPhotos {
  position: relative;
  flex-direction: row;
  height: 10rem;
  width: 12rem;
}

.photoWrapper {
  max-height: 12rem;
  overflow: hidden;
}

.previousPhoto {
  position: absolute;
  width: 12rem;
  margin: 0.5rem;
  border-color: #777;
  border-width: 1;
}