@import "~component-library/dist/styles/constants.scss";

.editNeededPermitButton {
    background-color: transparent;
    border-color: transparent;
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    border: 0;
    &:active, &:hover, &:focus {
      background-color: transparent;
      border-color: $gray05;
      box-shadow: none;
      color: $gray03;
    }
  }

  .sectionToggleButton {
    background-color: transparent;
    border-color: transparent;
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    &:active, &:hover, &:focus {
      background-color: transparent;
      border-color: $gray05;
      box-shadow: none;
      color: $gray03;
    }
  }

  .descColumn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 48rem;
    font-style: italic;
  }

  .dateTitle {
    padding-left: 0.8rem;
  }