@import "~component-library/dist/styles/constants.scss";

.calloutLink {
  border-radius: 0.4rem;
  background-color: RGB(229, 240, 250, .5);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 1.6rem;
  color: $boomerangBlue;
  padding: 1.4rem;
  line-height: 1;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  svg {
    margin-bottom: 0.4rem;
  }
}