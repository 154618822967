@import "~component-library/dist/styles/constants.scss";

.noMap {
  background-image: url("../../assets/bg-field.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapTitle {
  font-size: 2.4rem;
  padding: 0 2.5rem;
  color: $white;
}
.label {
  font-size: 1.6rem;
  color: $gray04;
  margin-top: 4rem;
}

.active {
  z-index: 999;
}