@import "~component-library/dist/styles/constants.scss";

.workingDayHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid $gray05;
}

.nonWorkingDayHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  background-color: $gray09;
  border-bottom: 1px solid $gray06;
}

.dayHeaderDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.dayHeaderDay {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4.5rem;
}

.indicator {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
}

.workingDay {
  color: $gray03;
}

.workingDayStatus {
  color: $darkBlue10;
  font-size: 3rem;
}

.reportDayBody {
  padding: 1rem 1rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 3rem;
  flex-wrap: wrap;
}