@import "~component-library/dist/styles/constants.scss";

.inlineRow {
  @media(min-width: 625px) {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

.dashSection {
  margin-bottom: 1.6rem;
}

.innerWrapper {
  flex: 1;
  width: 100%;
  position: relative;
  margin-top: 2.5rem;
  padding: 0 2rem 12.5rem;
}

.docLinks {
  display: flex;
  margin-left: -0.4rem;
  margin-right: -0.4rem;
  min-height: 11.2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.pageLinkWrap {
  width: 16%;
  display: flex;
  padding: 0.4rem;
  flex-shrink: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.pageLink {
  border-radius: 0.4rem;
  background-color: RGB(229, 240, 250, .5);
  display: flex;
  flex-grow: 1;
}

.docCalloutWrap {
  width: 84%;
  display: flex;
  padding: 0.4rem;
  min-height: 11.2rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.docCallout {
  border-radius: 0.4rem;
  background-color: $blue06;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  font-size: 2.4rem;
  color: $boomerangBlue;
  transition: .2s all ease-in-out;
  padding: 1.2rem 1.5rem;
  text-decoration: none;

  svg {
    margin: 0 0 -0.2rem 0.1rem;
  }
  &:hover {
    text-decoration: none;
    background-color: RGB(229, 240, 250, .8);;
  }
}

.milestonesList {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: -0.4rem;
  margin-right: -0.4rem;
}

.reportingLoading {
  border-radius: 0.4rem;
  flex-grow: 1;
  background-color: $gray09;
  min-height: 12.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.4rem;
}

.reportingCharts {
  display: flex;
  flex-grow: 1;
}

.reporting {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: -0.4rem;
  margin-right: -0.4rem;
  min-height: 12.8rem;
}

@media (max-width: 768px) {
  .reportingCharts, .reporting, .milestonesList, .milestones {
    flex-direction: column;
  }
}

.report {
  border-radius: 0.4rem;
  background-color: $gray09;
  margin: 0.4rem;
  padding: 0.8rem;
  flex: 1;
}

.milestoneWrap {
  display: flex;
  padding: 0.4rem;
  flex: 1;
}

.milestones {
  display: flex;
  flex-shrink: 1;
}

.milestone {
  background-color: $gray09;
  border-radius: 0.4rem;
  padding: 1.4rem 0.8rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.projectAlert {
  position: fixed;
  top: 2rem;
  z-index: 10000;
  width: 80%;
  left: 10%;
}