@import "~component-library/dist/styles/constants.scss";
.timesheet {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray05;
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}

.overAllocated {
  border-color: $red;
}

.header {
  display: flex;
  width: 100%;
  padding: 1.2rem;
  background-color: $gray09;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
}



.timesheetName {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-weight: 600;
  color: $boomerangBlue;
  line-height: 1;
  max-width: 9rem;
  overflow-wrap: break-word;
}

.totalHours {
  color: $gray02;
  font-size: 2.4rem;
}

.unassignedWrapper {
  padding: 0.8rem 1.3rem 1rem 1.4rem;
}

.unassignedInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  background-color: $gray08;
  border-radius: 0.8rem;
  color: $gray02;
  padding-right: 1rem;
  height: 4.8rem;
}

.hoursPill {
  min-width: 6rem;
  border-radius: 2rem;
  font-size: 2.4rem;
  text-align: center;
  color: $white;
  background-color: $yellowOrange;
}

.isDragging {
  opacity: 0.5;
}

.noDrag {
  padding-left: 2rem;
}

.zeroHours {
  color: $gray05 !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $gray05;
  opacity: 0.6;
}