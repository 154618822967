@import "~component-library/dist/styles/constants.scss";

.onSiteHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $darkBlue11;
  height: 4.8rem;
  padding-left: 1.5rem;
}

.onSiteHeaderText {
  color: $white;
  font-size: 1.6rem;
  margin-left: 0.4rem;
  font-weight: 600;
}

.onSiteHeaderCountWrap {
  display: flex;
  border-radius: 0.8rem;
  height: 1.6rem;
  margin-left: 0.8rem;
  min-width: 2.4rem;
  padding: 0 0.6rem;
  justify-content: center;
  align-items: center;
}

.onSiteHeaderCount {
  color: $white;
  font-size: 1.2rem;
}

.onSiteHeaderCountZero {
  color: #869EC4;
  font-size: 1.2rem;
}

.onSiteList {
  flex-shrink: 1;
  flex-grow: 0;
}

.onSiteListWrap {
  flex-shrink: 1;
  flex-grow: 0;
  overflow: hidden;
}

.countZeroWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #003C6D;
  height: 100%;
  padding: 3.2rem 0;
  font-size: 2.4rem;
}

.countZeroText {
  color: $white;
}

.countZeroLink {
  margin-top: 1.2rem;
  color: #FFA400;
  text-decoration: none;
  font-size: 1.6rem;
}
  .countZeroLink:hover {
    color: #FFA400;
    text-decoration: none;
  }
