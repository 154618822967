@import "~component-library/dist/styles/constants.scss";
.block {
  padding: 1.2rem;
  margin-right: 0.9rem;
  margin-bottom: 0.9rem;
  border-radius: 0.8rem;
}

.largeBlock {
  width: 19.7rem;
  height: 11rem;

  @media(min-width: 1450px) {
    min-width: 19.7rem;
    width: auto;
    flex: 1;
    height: 10.4rem;
  }
}

.smallBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 17rem;
  height: 4.8rem;
  margin-right: 0;
  margin-bottom: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.title {
  margin-bottom: 0;
  margin-left: 1.2rem;
  margin-right: 0.8rem;
  color: $darkBlue10;
  font-weight: 600;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
}

.countRow {
  margin-left: 3.5rem;
  color: $dark-text;
}

.count {
  font-weight: 600;
}

.linkWrapper {
  margin-top: 0.3rem;
  margin-left: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14.4rem;
  text-align: center;
  font-size: 1.4rem;
  color: $white;
  font-weight: 600;
  padding: 0.2rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  background-color: $boomerangBlue;
  
  svg {
    margin-left: 0.8rem;
  };
}