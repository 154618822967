@import "~component-library/dist/styles/constants.scss";

.body {
  padding: 2rem;
}

.imagesWrapper {
  display: flex;
  flex-wrap: wrap;
}

.imageWrapper {
  position: relative;
  max-width: 22%;
  margin-right: 1rem;
  margin-bottom: 1rem;

  img {
    width: 100%;
    border-radius: 0.4rem;
  }
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: $gray03;
  background-color: $gray05;
}

.imageGrid {
  flex-shrink: 1;
  flex-grow: 0;
  overflow: hidden;
  padding: 0;
  height: 17.5rem;
}

.submitButton {
  margin-top: 0;
  margin-left: 1rem;
}

.textArea {
  border: none;
  resize: none;
  width: 100%;
  padding: 1rem;
}

.photoNoteWrapper {
  border: 1px $gray05 solid;
  border-radius: .4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.photoSubmitBtn {
  font-size: 1.6rem;
  align-self: flex-end;
}