@import "~component-library/dist/styles/constants.scss";

.projectTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.name {
  color: $darkBlue11;
  font-weight: bold;
}

.title {
  color: $gray02;
  font-size: 2.25rem;
  font-weight: 600;
  margin-left: 0.5rem;
}

.subTitle {
  font-size: 1.6rem;
  color: $gray02;
  font-weight: 600;
  margin-left: 0.5rem;
}

.job_number {
  color: $gray03;
}