@import "~component-library/dist/styles/constants.scss";

.options {
  max-height: 25rem;
  overflow-y: auto;
  z-index: 1000;
  min-width: 100%;
  max-width: 90vw;
}

.option-table {
  table-layout: fixed;
}

.company {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.addLocation {
  width: 5rem;
}

.optionWrapper {
  max-height: 25rem;
}

.columnLine {
  border: 0!important;
  padding-bottom: 0.2rem!important;
  padding-top: 0.2rem!important;
}