@import "~component-library/dist/styles/constants.scss";

@mixin text-ellipsis($lines: 1) {
  text-overflow: ellipsis;
  overflow: hidden;
  @if ($lines > 1) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  } @else {
    white-space: nowrap;
  }
}

.header {
  padding: 1rem 2rem;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
}

.body {
  width: 100%;
  flex: 1;
  max-height: 60rem;
  padding: 1.2rem 2.4rem 2.4rem 2.4rem;
}

.dateDay {
  display: flex;
  align-items: center;
  margin-left: 3rem;
  color: $gray03;
  font-style: italic;
}

.createdDate {
  display: flex;
  align-items: center;
  font-style: italic;
  line-height: 1;
}

.avatar {
  margin-right: 1rem;
}

.title {
  color: $boomerangBlue;
  font-weight: 600;
}

.note {
  margin-bottom: 1rem;
  border: 1px solid $gray06;
  border-radius: 0.8rem;

  &.warn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  @media print {
    break-inside: avoid;
  }
}

.tableNote {
  border-top: none;
  border-color: #f7f7f7;
  border-width: 0.2rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -4px;
}

.notesBody {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0.8rem;
  margin: 0 1rem;
}

.noteName {
  margin-right: 0.5rem;
  color: $darkBlue11;
}

.time {
  color: $gray03;
  font-style: italic;
}

.icon {
  color: $gray03;
}

.noteContent {
  height: 100%;
  font-size: 1.6rem;
  color: $gray03;
  border-radius: 0.2rem;
  margin: 0 2rem 1rem 0;
}

.noteContentBg {
  padding: 0rem 1rem 1rem;
  margin-right: 1rem;
}

.noteContent p,
.noteContentBg p {
  display: block;
  width: 60.6rem;
  overflow-wrap: break-word;
}

.content {
  @include text-ellipsis($lines: 3);
  max-width: 900px;
  word-wrap: break-word;
  white-space: pre-line;
}

.condensedContent {
  @include text-ellipsis($lines: 2);
}

.deletedTimesheet {
  color: $gray03;
  font-style: italic;
  border-left: 2px solid $redOrange;
  align-items: center;
  display: flex;
  background-color: $gray09;
  border-radius: 0.2rem;
}

.noteTitle {
  color: $redOrange;
  font-weight: 600;
}

.iconDeleted {
  color: $redOrange;
  margin: 0 0.5rem;
}

.noteCount {
  color: $gray03;
}

.imageGroupWrapper {
  width: 10rem;
  display: flex;
  cursor: pointer;

  &.large {
    width: 15rem;
  }
  // hide when print
  @media print {
    display: none;
  }
}

.photosRow {
  width: 100%;
  display: none;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 0.8rem;
  @media print {
    display: flex;
  }
}

.printImage {
  width: 32%;
  object-fit: contain;
  align-self: start;
}

.firstImage {
  width: 9.6rem;
  height: 9.6rem;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.4rem;
}

.smImg {
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
  overflow: hidden;
  margin-left: 0.8rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.4rem;

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}

.imagesWrap {
  display: flex;

  img {
    width: 100%;
  }
}

.taskNameWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.8rem;
  font-weight: 600;
  color: $boomerangBlue;
}

.noImages {
  width: 100%;
}

.rfi {
  border-radius: 0.4rem;
  border-left: 0.8rem solid $redOrange;
}

.rfiIndicator {
  display: flex;
  align-items: center;
  color: $redOrange;
  font-weight: 600;
}

.secondCol {
  display: flex;
  flex-direction: column;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 2.4rem;
  color: $white;
}

.selectedIcon {
  justify-self: flex-end;
}

.workdayValueWrapper {
  display: flex;
  align-items: center;
  color: $gray03;
  font-size: 1.2rem;

  &:first-child {
    margin-right: 1.2rem;
  }
}

.workdayValue,
.stationValue {
  margin-left: 0.4rem;
  font-size: 1.6rem;
  font-weight: 600;
}

.workdayValue {
  color: $darkBlue09;
}

.stationValue {
  color: $darkBlue10;
}
