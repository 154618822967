@import "~component-library/dist/styles/constants.scss";

.fakeInput {
  width: 17.5rem;
  height: 3rem;
  border: 1px solid $gray05;
  border-radius: 0.5rem;
  color: $grayTableHeader;
  font-size: 1.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fakeDropdown {
  width: 17.5rem;
  height: 3rem;
  color: $grayTableHeader;
  font-size: 1.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}

.popoverWrapper {
  margin-top: -1.6rem;
  overflow-y: auto;
  max-height: 10rem;
}
