@import "~component-library/dist/styles/constants.scss";

.sliderSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 70vw;
  max-height: 70vh;
}

.thumbSection {
  display: flex;
  flex-direction: row;
  width: 100%;
}
