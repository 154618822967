@import "~component-library/dist/styles/constants.scss";

.stats {
  @media (min-width: 1700px) {
    margin-left: 3rem;
  }
}

.statsRow {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex: 1;
  min-height: 14.4rem;
}

.title {
  width: 100%;
  height: 4rem;
  color: $gray02;
  margin-bottom: 0;
  font-size: 1.6rem;
}

.subTitle {
  color: $secondary;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
}

.statsTitle {
  display: flex;
  margin-bottom: 1.2rem;
  color: $darkBlue10;
  font-size: 1.6rem;
  font-weight: 600;
  z-index: 10;

  span {
    color: $darkBlue11;
    font-weight: 400;
    vertical-align: text-top;
  }
}

.statBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  width: 16.375rem;
  height: 14.4rem;
  border-radius: 0.4rem;
  padding: 0.8rem 1.6rem;
  background-color: $gray08;
  color: $gray02;
}

.value {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  font-size: 5.5rem;
  font-weight: 700;
  line-height: 7.5rem;
  text-align: center;
}

.symbol {
  position: absolute;
  top: -1.75rem;
  right: -1.5rem;
  color: $gray;
  font-size: 2.4rem;
}

.error {
  max-width: 30rem;
  word-break: break-all;
}

.subValue {
  margin-left: 0.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: $secondary;
  line-height: 1;

  span {
    line-height: 1;
  }
}

.val {
  font-size: 1.7rem;
  font-weight: 600;
  color: $blue03;
}

.secondary {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.multipleText {
  font-weight: 600;
  color: $darkBlue10;
}