@import "~component-library/dist/styles/constants.scss";
  .photoListItemSelected {
    position: relative;
    background-color: $gray;
  }
  
  .photos {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
  }

  .photo {
    position: relative;
  }

  .close {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    padding: 0;
  }

  .imageWrapper {
    max-height: 12rem;
    overflow: hidden;
    margin: 0.5rem;
  }

  .taskName {
    max-width: 50rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
  }

  .thumbnail {
    max-width: 12.5rem;
  }