@import "~component-library/dist/styles/constants.scss";

.header {
  padding: 2rem 2.4rem;
  background-color: $gray09;
}

.closeButton {
  border: none;
  padding: 0;
  color: $gray05;
  background-color: transparent;

  &:hover,
  &:focus {
    color: $boomerangBlue;
    background-color: transparent;
  }
}

.body {
  padding: 3rem 2.4rem;
}

.footer {
  padding: 1.6rem 2.5rem;
  background-color: $gray09;
  border: none;
}