@import "~component-library/dist/styles/constants.scss";

.weatherTitle {
  margin-right: 2.1rem;
  margin-bottom: 0;
  font-weight: 600;
  color: $darkBlue10;
}

.hourlyWeatherWrapper {
  max-width: 100%;
  overflow: auto;
  margin-bottom: 3.8rem;
}

.hourlyBlock {
  margin-right: 4.2rem;
}

.value {
  margin-left: 0.8rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2rem;
  color: $darkBlue11;
}

.description {
  font-size: 1.2rem;
  color: $blue02;
  text-align: center;
  min-width: 6rem;
  line-height: 1;
}

.precip {
  margin-top: 0.4rem;
  text-align: center;
}

.timeSelectWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2.275rem;

  input {
    font-size: 1.6rem;

    &[type="time"]::-webkit-calendar-picker-indicator { background: none; display:none; }
  }
}

.startTime {
  font-size: 1.4rem;
  color: $darkBlue11;
}