@import "~component-library/dist/styles/constants.scss";

.header {
  display: flex;
  align-items: center;
  margin-bottom: 3.8rem;
  margin-top: 2.4rem;
}

.userName {
  color: $darkBlue11;
  margin-right: 1.6rem;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.3rem;
}

.hireText {
  color: $darkBlue11;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.3rem;
}