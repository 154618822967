@import "~component-library/dist/styles/constants.scss";
.header {
  display: flex;
  align-items: center;
  padding: 0.5rem 2.5rem;
  height: 6.4rem;
  margin-bottom: 2.2rem;
  color: $darkBlue11;
  background-color: $gray09;
  border-radius: 0;
}

.titleText {
  font-size: 2rem;
  color: $darkBlue11;
  font-weight: 600;
}

.backdrop {
  background-color: $darkBlue11;
  opacity: .8 !important;
}

.modalContent {
  border-top: 0;
  border-radius: 0.4rem;
  padding: 0;
  height: calc(100vh - 9.6rem);
  display: flex;
  flex-direction: column;
}

.dialogWrap {
  width: 90%;
  max-width: 120rem;
}

.modalBody {
  flex-shrink: 1;
  flex-grow: 0;
  overflow: hidden;
  padding: 0;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.footer {
  height: 8rem;
  border-top: none;
  background-color: $gray09;
}

.toggleAll {
  font-weight: 600;
  padding: 0;
  margin-bottom: 1.6rem;
}