@import "~component-library/dist/styles/constants.scss";

.foremenTitle {
  margin-bottom: 0;
  margin-right: 0.4rem;
  color: $gray02;
  font-size: 1.6rem;
  font-weight: 600;
}

.foremenCount {
  font-style: italic;
  font-size: 1.6rem;
  color: $gray02;
}

.foremanGrid {
  display: grid;
  grid-template-columns: repeat(6, minmax(3rem, 1fr));
  column-gap: 0.8rem;
  row-gap: 0.8rem;
}