@import "~component-library/dist/styles/constants.scss";
.content {
  section {
    padding-right: 3.5rem;
  }
}

.sidebar {
  @media (min-width: 768px) {
    border-left: 1px solid $gray08;
  }
}