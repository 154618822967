@import "~component-library/dist/styles/constants.scss";

.addMilestone {
  background-color: $gray09;
  border-radius: 0.5rem;
}
.currencyInput {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid $gray05;
  padding: 0 1rem;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: $white;
  display: flex;
  align-items: center;
  &:focus {
    outline: none;
  }
  input {
    display: inline;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    font-size: 1.6rem;
    font-weight: 500;
  }
}

// hide arrows on input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
