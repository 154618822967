@import "~component-library/dist/styles/constants.scss";

.dateFieldWrapper {
  border: 1px solid $gray05;
  border-top: 0;
  padding: 2rem 1.6rem;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.fieldSection {
  margin-bottom: 3.2rem;

  h3 {
    display: block;
    margin-bottom: 0;
    min-width: 13.2rem;
    font-weight: 600;
    color: $darkBlue10;
  }
}

.columnWrapper {
  @media(min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
}

.column {
  flex: 1;

  &:not(:first-child) {
    @media(min-width: 992px) {
      margin-left: 2rem;
    }
  }
}