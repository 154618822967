@import "~component-library/dist/styles/constants.scss";

.status {
  text-transform: uppercase;
  text-align: center;
  font-size: 0.9rem;
  height: 1.6rem;
  padding: 0 0.8rem;
  font-weight: 600;
  border-radius: 0.8rem;
  color: $white;
  background-color: $gray05;
  line-height: 1.6rem;
  min-width: 6.4rem;
}