@import "~component-library/dist/styles/constants.scss";
.wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;

  > div {
    min-width: 7.5rem;
    text-align: right;
  }
}

.label {
  color: $gray03;
  text-transform: uppercase;
}

.value,
.hourValue {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 3.4rem;
  line-height: 1;
}

.value {
  color: $darkBlue11;
}

.hourValue {
  color: $green;
}

.current {
  font-weight: 400;
  font-size: 3.4rem;
  color: $darkBlue10;
}

.subText {
  font-size: 1.6rem;
  font-weight: 400;
  color: $gray03;
}

.warn {
  color: $redOrange;
}