@import "~component-library/dist/styles/constants.scss";

.folderName {
  margin-left: 1rem;
  color: $darkBlue11;
}

.attachmentCount {
  margin-left: 1rem;
  font-size: 1.4rem;
  color: $gray03;
}