@import "~component-library/dist/styles/constants.scss";

.wrapper {
  margin-bottom: 5rem;
}

.moduleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray02;
}

.subItems {
  padding-left: 0.5rem;
}

.subItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray05;
  padding: 0.5rem 0;
}

.submitButton {
  margin-top: 0;
}