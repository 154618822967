@import "~component-library/dist/styles/constants.scss";

.searchWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.notImportable {
    justify-content: flex-end;
  }
}