@import "~component-library/dist/styles/constants.scss";

.title {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  padding-left: 1.6rem;
  height: 5.6rem;
  width: 100%;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  text-decoration: none !important;
  color: $darkBlue11;

  span {
    font-weight: 400;
  }
}

.heading {
  font-weight: 600;
}

.count {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.name {
  font-weight: 600;
  color: $boomerangBlue;
  font-size: 1.8rem;
  text-align: left;
}

.foremen {
  width: 100%;
  margin-bottom: 2rem;
  border: 1px solid $gray05;
  border-radius: 0.3rem;
}

.foreman {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $gray06;
  border-bottom: 1px solid $gray06;
  padding: 0.8rem;

  &:nth-child(odd) {
    background-color: $gray08;

    label::before {
      outline: 1px solid $gray05;
    }
  }
}

.hours {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
}

.details {
  flex: 1;
}

.selectAll {
  display: flex;
  align-items: center;
  padding: 1rem 0.8rem;
}

.hours {
  font-size: 2.4rem;
  color: $gray02;
}

.sheetWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timeWrapper {
  display: flex;
  align-items: center;
  width: 9rem;
  margin-right: 0.8rem;
  font-size: 1.4rem;
}
