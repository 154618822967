@import "~component-library/dist/styles/constants.scss";

.resetPhase {
  color: $gray02;

  &:hover,
  &:focus {
    color: $gray02;
  }
}

.smallColumn {
  min-width: 3.5rem;
}

.smMedColumn {
  width: 6.5rem;
}

.medColumn {
  width: 8.5rem;
  min-width: 8.5rem;
  padding: 0 0.5rem;
}

.lgColumn {
  width: 12.5rem;
}

.taskTable {
  vertical-align: middle;
  border-bottom: 1px solid $gray04;

  thead {
    th {
      vertical-align: middle;
      padding: 0.5rem;
      border-left: 1px solid $gray05;
      border-right: 1px solid $gray05;
      border-bottom: 1px solid $gray04 !important;
    }
  }

  td {
    padding: 0.5rem;
    border: 1px solid $gray05;
    vertical-align: middle;
  }

  input {
    height: 2.4rem;
  }

  .phaseHeaderRow {
    height: 1.6rem;

    th {
      height: 1.6rem;
      padding: 0;
      border: none !important;
    }
  }
}

.topRowBorder {
  border-top: 1px solid $grayConflict;
}

.phaseHeader {
  justify-content: space-between;
  color: $gray02;
  font-weight: 400;
  font-size: 1rem;
}

.phaseHeaderInner {
  background-color: $gray08;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 8.5rem;
}

.tableHeader {
  height: 3.5rem;
  text-transform: uppercase;
  color: $grayTableHeader;
  font-weight: 600;
  font-size: 1.4rem;
  text-align: right;
}

.taskName,
.materialName {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
}

.materialName {
  font-weight: 600;
}

.view {
  display: flex;

  @media (max-width: 992px) {
    overflow-x: auto;
  }
}

.fixed {
  border-spacing: 0;

  @media(min-width: 992px) {
    overflow-x: auto;
    width: calc(100% - 55rem);
    
    &.phasesActive {
      overflow-x: auto;
    }
  }

  td {
    height: 3.5rem;
    padding: 0.5rem;
  }
}

.scrollTableWrapper {
  width: 65rem;

  table {
    border-spacing: 0;

    td {
      padding: 0.5rem !important;
      height: 3.5rem;
    }
  }

  &.phasesActive {
    width: 70rem;
  }

  @media(min-width: 992px) {
    overflow-x: auto;
  }
}

.openTaskHeader {
  td {
    font-weight: 600;
    background-color: $grayBlue;
  }
}

.hasBorder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  border-bottom: none !important;
  border-top: none !important;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -0.6rem;
    left: -0.1rem;
    width: 0.5rem;
    height: 3.5rem;
    background-color: $boomerangBlue;
  }
}

.materialHasBorder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  height: 3.5rem;
  border-bottom: none !important;
  border-top: none !important;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -0.1rem;
    width: 0.5rem;
    height: 3.5rem;
    background-color: $boomerangBlue;
  }
}

.noBorder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
}

.emptyTd {
  border-bottom: none !important;
  border-top: none !important;
  border-left: none !important;
  background-color: $white !important;
}

.whiteBg {
  td {
    background-color: $white;
  }
}

.lightBg {
  td {
    background-color: $darkBlue04;
  }
}

.darkBg {
  td {
    background-color: $darkBlue05;
  }
}

.warningBg {
  td {
    background-color: $red01;
  }
}

.phaseCell {
  min-width: 6.5rem;

  &.empty {
    background-color: $gray08 !important;
  }
}

.total {
  padding-top: 0.2rem;
  font-size: 1.4rem;
}

.bidQuantity {
  font-weight: 600;
}

.subBorderLeftActive {
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0.5rem;
    height: calc(100% + 0.1rem);
    background-color: $darkBlue03;
  }
}

.smInput {
  width: 5.5rem;
}

.name {
  font-weight: 600;
  color: $gray02;
}

.checklistCheckbox {
  &::before {
    width: 1.2rem !important;
    height: 1.2rem !important;
    margin-right: 0 !important;
    background-color: $gray05 !important;
    border: 1px solid $checkboxGray;
  }
}

.unSelected {
  &::before {
    width: 1.2rem !important;
    height: 1.2rem !important;
    margin-right: 0 !important;
  }
}

.rightInput {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.leftInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.taskCostCode {
  border: none !important;
  background-color: $white !important;
}

.rateWrapper {
  line-height: 1;
  max-width: 14.5rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activeCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    margin-right: 0 !important;
    border: 1px solid $gray05;
  }
}

.selectedLeadingCostCode,
.unselectedLeadingCostCode {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
}

.selectedLeadingCostCode {
  background-color: $boomerangBlue;
}

.unselectedLeadingCostCode {
  background-color: $gray05;
}

.moveIcon {
  color: $gray03;

  &:hover,
  &:focus {
    color: $boomerangBlue;
  }
}

.leadingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 1.6rem;
  width: 1.6rem;
  background-color: $boomerangBlue;
  color: $white;
  border-radius: 50%;
  text-align: center;
}

.taskLink {
  font-weight: 600;
  color: $boomerangBlue;
}

.forcedQuantityCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    margin-right: 0 !important;
    border: 1px solid $gray05;
  }
}