@import "~component-library/dist/styles/constants.scss";

.whiteBg {
  td {
    background-color: $white;
  }
}

.lightBg {
  td {
    background-color: $darkBlue04;
  }
}

.darkBg {
  td {
    background-color: $darkBlue05;
  }
}

.warningBg {
  td {
    background-color: $red01;
  }
}

.noBorder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
}

.emptyTd {
  border-bottom: none !important;
  border-top: none !important;
  border-left: none !important;
  background-color: $white !important;
}