@import "~component-library/dist/styles/constants.scss";

.container {
  margin: 1.6rem auto;
  height: 23.2rem;
  width: 52.8rem;
  background: repeating-linear-gradient(120deg, $gray06, $gray06 8px, $gray09 8px, $gray09 16px);
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
}

.cardWrapper {
  height: 21.6rem;
  width: 52.8rem;
  background: $gray09;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p,
  h2 {
    text-align: center;
  }

  h2 {
    margin-bottom: 1.2rem;
    color: $darkBlue11;
    font-size: 2.4rem;
    font-weight: 600;
  }
  p {
    font-weight: 400;
    font-size: 1.6rem;
    color: $gray02;
    width: 50%;
  }
}

.logo {
  width: 5.4rem;
  height: 5.4rem;
  background-color: $red01;
  border-radius: 50%;
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
