@import "~component-library/dist/styles/constants.scss";

.quoteTableWrapper {
  margin-top: 1rem;
  margin-bottom: 2rem;
  border: 1px solid $gray08;
  border-radius: 0.4rem;
}

.pm-takeoff-input {
  width: 6.6rem;
}
.icon {
  margin-right: 1rem;
  color: $gray03;
}

.tasksWrapper {
  margin-top: 1rem;
  border-top: 1px solid $gray06;
}

.taskWrapper {
  margin-bottom: 1rem;
  border-bottom: 1px solid $gray06;
}

.topLevelTask {
  height: 4.1rem;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.subtask-block {
  padding-right: 2rem;
  margin-top: 0.5rem;
  height: 4.1rem;
}

.material-block {
  padding-right: 2rem;
  margin-top: 0.5rem;
  height: 4.1rem;
}