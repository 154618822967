@import "~component-library/dist/styles/constants.scss";

.slide {
  max-height: 70rem;

  img {
    width: 100%;
    max-height: inherit;
    object-fit: contain;
  }
}
