@import "~component-library/dist/styles/constants.scss";

.modalTitle {
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;
  margin-left: 0.8rem;
  margin-bottom: 0;
}

.formTitle {
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;
  margin-bottom: 0.8rem;
}

.subtitle {
  color: $gray03;
}

.fileBorder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $boomerangBlue;
  font-weight: 700;
  border-radius: 0.4rem;
  border: 1px solid $gray05;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.preview {
  margin-top: 2rem;
  width: 20rem;
  height: 15rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}