@import "~component-library/dist/styles/constants.scss";
.consoleTitle {
  font-size: 1.8rem;
  font-weight: 600;
}

.consoleRow {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.wrapper {
  position: relative;
  margin-bottom: 1.2rem;

  &.open {
    min-height: 11rem;
  }
}

.header {
  display: flex;
  align-items: center;
  min-height: 4rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(17rem, 1fr));
  column-gap: 0.8rem;
  row-gap: 0.8rem;
}