@import "~component-library/dist/styles/constants.scss";

.wrapper {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $indigo;
  border-bottom: 1px solid $darkBlue11;
  padding: 1.2rem 1.6rem 0.8rem 1.6rem;
  max-width: 28rem;
}

.crew_container {
  margin-left: 1.2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.crew_name {
  margin-top: -0.4rem;
  font-size: 1.6rem;
  color: white;
  display: flex;
}

.crew_meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.time_wrapper {
  display: "flex";
  flex-direction: row;
  color: $blue03;
  font-size: 1.4rem;
}

.bubble {
  background-color: #325a96;
  border-radius: 1rem;
  height: 2rem;
  justify-content: flex-end;
}

.bubble_text {
  color: #aebfd8;
  font-size: 1.4rem;
  padding: 0 0.6rem;
}