@import "~component-library/dist/styles/constants.scss";

.dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.header {
  color: $darkBlue10;
  font-size: 1.8rem;
  font-weight: 400;
}

.marker-blue path {
  fill: $boomerangBlue;
}

.marker-dark-blue path {
  fill: $darkBlue;
}

.marker-blue-green path {
  fill: $blueGreen;
}
.marker-dark-blue-green path {
  fill: $darkBlueGreen;
}
.marker-green path {
  fill: $green;
}
.marker-dark-green path {
  fill: $darkGreen;
}
.marker-yellow path {
  fill: $yellow;
}
.marker-yellow-orange path {
  fill: $yellowOrange;
}
.marker-orange path {
  fill: $orange;
}

.job-alert {
  position: relative;
  background-color: #fff;
  border-radius: 0.4rem;
  box-shadow: $shadow;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
  padding: 1.2rem 2.4rem 1.2rem 0.6rem;

  &:hover {
    text-decoration: none;

    svg {
      transform: translateX(0) translateY(-50%);
    }
  }

  &.level-1 {
    border-left: 6px solid $yellow;
  }

  &.level-2 {
    border-left: 6px solid $yellowOrange;
  }

  &.level-3 {
    border-left: 6px solid $orange;
  }

  svg {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateX(-0.3rem) translateY(-50%);
    transition: transform 250ms;
  }
}

.job-alert-type {
  font-size: 1rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  color: #797c84;
  font-weight: 600;
  text-transform: uppercase;
}

.job-alert-title {
  font-size: 1.2rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  color: #797c84;
}

.job-alerts {
  max-height: 30rem;
  overflow-y: auto;
  margin: 0 -1rem;
  padding: 2.5rem 1rem 0 1rem;
}

.job-alerts-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wrap {
  padding-left: 2rem;
}

.chiclet {
  box-shadow: $shadow;
  padding: 1.2rem;
  border-radius: 0.4rem;
  background-color: #fff;
}

hr {
  margin: 0.8rem 0;
}

.total {
  position: absolute;
  width: 15rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 0;
}

.total-count {
  font-size: 2.1rem;
  color: #54565a;
  line-height: 1;
}

.total-label {
  font-size: 0.8rem;
  color: #54565a;
  text-transform: capitalize;
}

.chart-legend-wrapper {
  margin-top: -4.4rem;
}

.legendDD {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 0;
  line-height: 1;

  &.small {
    font-size: 1.2rem;
  }

  &.large {
    font-size: 2.4rem;
  }

  &.xlarge {
    font-size: 4rem;
  }

  &.negative-value {
    color: $red !important;
  }
}

.legendDT {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: $grayConflict;
}

.invoiceRow {
  .invoiceTh {
    font-weight: normal;
    padding-left: 0 !important;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    color: $secondary;
    span {
      color: $gray;
      font-style: italic;
      text-transform: none;
      font-size: 1.2rem;
    }
  }
  .invoiceTd {
    padding-right: 0 !important;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: right;
    color: $boomerangBlue;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  &:first-child {
    td,
    th {
      border-top: 0 none !important;
      vertical-align: middle;
    }
  }
  .invoiceTotal {
    color: $darkBlue;
  }
  .due {
    color: $orange;
  }
  .retainage {
    color: $gray;
  }
}
.invoicePrint {
  flex-grow: 0.5;
  padding-right: 1rem;
}

.status-overall {
  @media (min-width: 480px) {
    grid-column: 1 / span 2;
  }
}

.chart {
  position: relative;
}

.job-title {
  font-size: 1.6rem;
}

.percent {
  font-size: 5.76rem;
  line-height: 0.9;
  margin-top: 1.8rem;
  font-weight: 600;
  color: #797c84;
}

.good {
  color: $blueGreen;
}

.bad {
  color: #ea5644;
}

.units {
  font-size: 2.56rem;
  color: $gray;
  position: relative;
  top: -1.1rem;
  margin-left: 0.32rem;
}

.label {
  text-transform: uppercase;
}

.secondary {
  display: flex;
  align-items: center;
}

.secondaryFigure {
  font-size: 1.92rem;
  font-weight: bold;
  margin-right: 0.4rem;
}
.balance {
  color: $boomerangBlue;
  font-size: 1.6rem;
  font-weight: bold;
}

.boomboardGrid {
  display: grid;
  column-gap: 2rem;
  row-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(24.6rem, 1fr));
}

.homeChartWrap {
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.tall {
    min-height: 23.9rem;
  }
}

.smallerChartWrap {
  min-height: 17.2rem;
}

.legend {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  div {
    display: flex;
    align-items: center;
  }
  dd {
    margin: 0 1rem 0 0;
    font-size: 1rem;
  }
  dt {
    margin-right: 0.5rem;
  }
}

.dollarsPerJobCenterLabel {
  max-width: 5rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  color: $boomerangBlue;
  text-transform: capitalize;
}

.dollarsPerJobTotal {
  position: absolute;
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 0;
}

.totalBase {
  position: absolute;
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 0;
}

.totalLg {
  width: 7rem;
  height: 7rem;
  z-index: 2;
  top: 4rem;
  align-self: center;

  .total-count {
    font-size: 2.1rem;
    color: $boomerangBlue;
    line-height: 1;
  }

  .dollarsPerJobCenterLabel {
    font-size: 1.6rem;
    line-height: 1.25;
    font-weight: 500;
    text-align: center;
    color: $boomerangBlue;
    text-transform: capitalize;
  }
}

.compact {
  .percent {
    font-size: 4rem;
    margin-top: 0.8rem;
  }
  .units {
    font-size:2.4rem;
    top: -0.8rem;
  }
  .label {
    font-size: 1.2rem;
  }
  .secondaryFigure {
    font-size: 1.6rem;
  }
  .legendDT {
    margin-bottom: 0.4rem;
  }
  .totalBase {
    width: 8.6rem;
    height: 8.6rem;
  }
  .legendDD {
    &.small {
      font-size: .875rem;
    }
  }
}