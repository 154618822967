@import "~component-library/dist/styles/constants.scss";

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 19rem);
  column-gap: 0.2rem;
  row-gap: 0.2rem;
  margin-top: 8rem;
}

.imgWrapper {
  position: relative;
  width: 100%;
  height: 18.4rem;
  margin-bottom: 1rem;

  &:hover,
  &:focus {
    .overlay {
      display: flex;
    }
  }

  a {
    display: block;
    width: 100%;
    height: 18.4rem;
    background-color: $gray06;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.overlay {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 113, 206, 0.75);
}