@import "~component-library/dist/styles/constants.scss";

.modalFieldTitle {
  width: 12.5rem;
  text-align: right;
  margin-bottom: 0;
  color: $gray03;
}

.header {
  margin-bottom: 4.8rem;
  padding: 2rem 2.4rem;
  background-color: $gray09;
}

.title {
  margin-left: 0.8rem;
  font-weight: 600;
  font-size: 2rem;
  color: $darkBlue11;
}

.modalCancelButton {
  margin: 0;
  border-color: $gray05;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  background-color: $gray05;
}

.footer {
  border: none;
  background-color: $gray09;
  padding: 1.6rem 2.5rem;
  margin-top: 6rem;
}

.submitButton {
  font-weight: 600;
  margin: 0 0 0 0.8rem;
}

.fileBorder {
  display: flex;
  height: 4.8rem;
  padding: 2.4rem 1.5rem;
  align-items: center;
  border: 1px solid $gray05;
}

.modalIcons {
  margin-right: 0.8rem;
}

.simplifiedTitle {
  font-weight: 600;
  max-width: 20rem;
}

.archiveWrap,
.dropzone {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  border: 1px solid $gray05;
  padding: 0 1.6rem;
  width: 25rem;
  min-height: 4.8rem;
  background-color: $white;

  .removeIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.8rem;
  }
}