@import "~component-library/dist/styles/constants.scss";

.mapWrapper {
  width: 100%;
  height: 100%;
  background-image: url(./../../assets/bg-field.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  flex-grow: 1;

  .notice {
    color: $white;
    font-size: 2rem;
    text-align: center;
    font-weight: semibold;
    padding: 1rem;

    a {
      color: $white;
      text-decoration: underline;
    }
  }
}