@import "~component-library/dist/styles/constants.scss";

.navContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $indigo;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 4rem;
  height: 4rem;
  background-color: $darkBlue11;
  color: $blue03;
}

.otherActions {
  margin-right: 2rem;
  padding: 0;
  background-color: transparent;
  border: none;
}