@import "~component-library/dist/styles/constants.scss";

.letterButton {
  font-size: 1.6rem;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;

  &.letActive {
    background-color: $blueTenPercent;
    font-weight: bold;
  }
  &:active, &:hover, &:focus {
    text-decoration: none;
  }
}