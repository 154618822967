@import "~component-library/dist/styles/constants.scss";

.number {
  max-width: 110px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  @media print {
    max-width: 100px;
  }
}
.numberNumber {
  font-size: 3.25rem;
  line-height: 3.5rem;
  font-weight: 600;
  @media print {
    font-size: 3rem;
    line-height: 2rem;
  }
}

.numberLabel {
  font-size: 1.4rem;
  font-weight: 400;
  color: $darkBlue11;
  @media print {
    font-size: 1rem;
    margin: .5rem 0;
  }
}