@import "~component-library/dist/styles/constants.scss";

.filterOption {
  position: relative;
  color: $gray02;
  font-size: 1.5rem;
}

.active {
  font-weight: bold;

  &::after {
    position: absolute;
    bottom: -0.4rem;
    left: 0;
    display: block;
    content: "";
    height: 0.2rem;
    width: 100%;
    background-color: $boomerangBlue;
  }
}