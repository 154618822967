@import "~component-library/dist/styles/constants.scss";

.modalTitle {
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;
  margin-left: 0.8rem;
  margin-bottom: 0;
}


.formTitle {
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;
  margin-bottom: 0;
}

.subtitle {
  color: $gray03;
}