@import "~component-library/dist/styles/constants.scss";

.equipment {
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  height: 3rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: $darkBlue11;

  span {
    font-weight: 400;
  }
}

.equipID {
  margin-right: 1rem;
  font-weight: 600;
  color: $boomerangBlue;
  font-size: 1.8rem;
}

.singleEquipment {
  display: flex;
  align-items: center;
  border: 1px solid $gray06;
  border-bottom: none;
  border-radius: 0.8rem;
  padding: 0.8rem;
  min-width: 23rem;
  margin-bottom: 1rem;

  &:nth-child(odd) {
    background-color: $gray08;
  }
}

.hours {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15rem;
  border: 2px dashed $gray05;
  color: $grayConflict;
  border-radius: 0.8rem;
}

.equipmentName {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: 16rem;

  @media (max-width: 768px) {
    width: fit-content;
  }
}