@import "~component-library/dist/styles/constants.scss";

.table {
  margin-bottom: 4rem;
  text-align: left;
  width: 100%;
  overflow-y: visible !important;

  th {
    border-top: none !important;
    border-bottom: none !important;
  }
  td {
    padding: 0.5rem 0;
    border: none !important;
    align-items: center;
    vertical-align: middle;
    
    &:first-child {
      @media (min-width: 768px) {
        width: 30rem;
      }
    }
  }
}

.tableHead {
  th {
    font-weight: 400;
    font-size: 1.4rem;
    color: #7B7B7B;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    padding-left: 0;
    
    &:last-child {
      text-align: right;
    }
  }
}

.taskName {
  display: inline-block;
  width: 100%;
  max-width: 24.5rem;
  color: $boomerangBlue;
  font-weight: 600;

  span {
    max-width: 24.5rem;
  }
}

.select {
  height: 95% !important;
  max-width: 26.5rem;
  border-radius: 0.4rem;
}