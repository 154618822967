@import "~component-library/dist/styles/constants.scss";

@for $i from 1 through 10 {
  .statusOption#{$i} {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 15rem;
    &:hover {
      background-color: $blue05;
    }
    &:before {
      background-color: #C4C4C4;
      border-radius: 0.3rem;
      content: " ";
      display: "block";
      margin-right: 0.6rem;
      height: 0.6rem;
      width: 0.6rem;
      flex-shrink: 0;
      align-self: center;
    }
  }
}

.statusOption1 {
  &:before {
    background-color: #C4C4C4!important;
  }
}

.statusOption2 {
  &:before {
    background-color: #F0C808!important;
  }
}

.statusOption3 {
  &:before {
    background-color: #F0C808!important;
  }
}

.statusOption4 {
  &:before {
    background-color: #A5E46F!important;
  }
}

.statusOption5 {
  &:before {
    background-color: #A5E46F!important;
  }
}

.statusOption6 {
  &:before {
    background-color: #FFA400!important;
  }
}

.statusOption7 {
  &:before {
    background-color: #FF6700!important;
  }
}