@import "~component-library/dist/styles/constants.scss";

.toggleButton {
  width: 50%;
  color: $gray03;
  font-weight: 600;
  color: $gray04;
  background-color: $white;
  height: 4rem;
  font-size: 1.6rem;
  border: 1px solid $gray06;

  &:hover,
  &:focus {
    color: $gray04;
    background-color: $white;
  }
}

.active {
  color: $gray03;
  background-color: $gray06;

  &:hover,
  &:focus {
    color: $gray03;
    background-color: $gray06;
  }
}

.toggler {
  margin-right: 0.5rem;
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15rem;
  border: 2px dashed $gray05;
  color: $grayConflict;
  border-radius: 0.8rem;
}

.sidebarInner {
  @media (min-width: 768px) {
    height: 60rem;
  }
}