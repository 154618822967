@import "~component-library/dist/styles/constants.scss";

.durationDot {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.6rem;
  margin-right: 1.1rem;
  display: block;
}

.dateCell {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: row;
}

.gridWrap {
  flex: 1;
  padding: 4rem 4rem 2.4rem 2.4rem;
  display: flex;
  flex-direction: column;
}

.gridControls {
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: 0.7rem;
  display: flex;
  align-items: center;
}

.gridFooter {
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 0.7rem;
  display: flex;
  justify-content: baseline;
  align-items: center;
  font-size: 1.4rem;
  color: $gray03;
}

.footerCheck {
  margin-right: 0.4rem;
  border-color: $borderGray;
  input + label {
    font-size: 1.4rem;
    &:before {
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 0.3rem;
      border-color: $gray05;
      background-color: $gray08;
      border-width: 1px;
    }
  }
}

.search {
  width: 22.4rem;
}

.gridWrapInner {
  flex: 1;

  display: flex;
  flex-direction: column-reverse;
  
  
  @media (min-width: 1200px) {
    height: 100%;
    flex-direction: row;
  }
}


.tableWrapper {
  flex: 1;
  height: 50rem;
  @media (min-width: 1200px) {
    height: auto;
    flex: 1;
    height: auto;
  }
}

.sidebar {
  width: 100%;

  @media (min-width: 1200px) {
    width: 24rem;
    padding-left: 2rem;
  
    &.medium {
      width: 60rem;
      padding-left: 0;
    }
  
    &.large {
      width: 83rem;
      padding-left: 0;
    }
  }
}

.iconTag {
  width: 1.8rem;
  height: 1.8rem;
  z-index: 2;
  top: -1.2rem;
  left: 1.2rem;
  position: absolute;

  &.inline {
    position: relative;
    top: auto;
    left: auto;

    svg {
      vertical-align: baseline;
    }
  }
}

.initials {
  font-size: 1.2rem;
  font-weight: 600;
  color: $white;
  text-transform: uppercase;
  width: 2rem;
  height: 2rem;
  border-radius: 0.2rem;
  margin-right: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-family: "Source Sans Pro", sans-serif;

  &.large {
    width: 4rem;
    height: 4rem;
    font-size: 2.4rem;
    margin-right: 1.8rem;
    border-radius: 0.4rem;
  }

  &.medium {
    width: 3.2rem;
    height: 3.2rem;
    font-size: 2rem;
    border-radius: 0.3rem;
  }
}