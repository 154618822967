@import "~component-library/dist/styles/constants.scss";

.unassigned {
  margin-right: 0.5rem;
  color: $gray02;
  font-weight: 600;
}

.mapWrapper {
  height: 18.5rem;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}