@import "~component-library/dist/styles/constants.scss";

.modalTitle {
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;
  margin-left: 0.8rem;
  margin-bottom: 0;
}


.formTitle {
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;
  margin-bottom: 0;
}

.subtitle {
  color: $gray03;
}

.currentTitleWrapper {
  display: flex;
  align-items: center;
  margin-top: 1.6rem;

  border-radius: 0.4rem;
  padding: 0.8rem;
  background-color: $gray09;
}

.currentTitle {
  margin-left: 0.8rem;
  color: #082043;
  line-height: 2.4rem;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.6rem;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  background-color: #FFEFE5;

}