@import "~component-library/dist/styles/constants.scss";

.popover {
  max-height: 22rem;
  min-width: 25rem;
  overflow: auto;
}

.header {
  margin-bottom: 0.9rem;
  padding-bottom: 0.9rem;
  border-bottom: 1px solid $gray05;
}

.wrapper {
  padding-left: 0.5rem;
}

.option {
  display: block;
  width: 100%;
  border-radius: 0.8rem;
  margin-bottom: 0.5rem;

  &:hover,
  &:focus {
    border-radius: 0.8rem;
    background-color: $gray08;
  }
}

.timePill {
  font-size: 1.4rem; 
  color: $white;
  background-color: $orange;
  border-radius: 1.2rem;
  padding: 0.2rem 0.5rem;
  text-decoration: none;
}

.error {
  border: 1px solid $red;
}

.formControlIcon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: $gray05;
}