@import "~component-library/dist/styles/constants.scss";

.close {
  position: absolute;
  right: 0;
  top: 0;
}

.semibold {
  font-weight: 600;
}

.locationTitle {
  font-weight: 600;
}