@import "~component-library/dist/styles/constants.scss";


.statsWrap {
  display:flex;
  flex-direction: column;
  text-align: right;

  &:not(:last-child) {
    margin-right: 3.6rem; 
  }
}

.dayStatsRow {
  display: flex;
  align-items: center;
}

.text {
  font-weight: 600;
  font-size: 4rem;
  line-height: 1;
}

.darkText {
  color: $darkBlue10;
}

.lightText {
  color: $boomerangBlue;
}

.orangeText {
  color: $redOrange;
}

.subText {
  color: $darkBlue10;
  font-size: 4rem;
}