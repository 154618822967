@import "~component-library/dist/styles/constants.scss";

.headerWrap {
  background-color: $gray08;
  padding-left: 2.4rem;
  padding-right: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.secondary {
  max-width: 60rem;
  margin: 0 auto 1.5rem;
}

.itemWrap {
  margin: 0.8rem 0;
  border: 1px solid $gray08;
  border-radius: 0.8rem;
}

.dropdownContainer {
  padding: 1.2rem 2.4rem 0;
}

.groupContainer {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 100rem;
}

.checkboxContainer {
  width: 100%;

  @media (min-width: 728px) {
    width: 50%;
  }

  @media (min-width: 1200px) {
    width: 33.33%;
  }
}

.pillBadge {
  padding: 0 1.2rem;
  color: $boomerangBlue;
  font-weight: 700;
  border-radius: 1.6rem;
  background-color: $blueTenPercent;
}

.removeLoaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.inlineRow {
  width: 100%;

  @media(min-width: 625px) {
    display: flex;
    flex-direction: row;
  }
}

.checklistCheckbox::before {
  background-color: $gray05 !important;
}

.teamSelect {
  width: 24.5rem;
}

.dotCatForm {
  min-height: 7.6rem;
}

.preconTitle {
  font-weight: 600,
}

.launcherWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tinyText {
  font-weight: 400;
  color: $grayConflict
}