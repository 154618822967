@import "~component-library/dist/styles/constants.scss";

.user {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: $gray02;
}

.edit {
  border: none;
  background-color: transparent;
  margin-right: 1.1rem;
  padding: 0;
}

.avatarWrap {
  position: relative;
  min-width: 2.4rem;
  max-width: 2.4rem;
  min-height: 2.4rem;
  max-height: 2.4rem;

  .editIcon {
    color: white;
    transition: opacity 0.1s ease-in-out;
    z-index: 99;
  }

  &:hover {
    .editIconWrap {

    opacity: 1;
    }
  }
}
.editIconWrap {
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 2.4rem;
  max-width: 2.4rem;
  min-height: 2.4rem;
  max-height: 2.4rem;
  border-radius: 0.4rem;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s ease-in-out;
}