@import "~component-library/dist/styles/constants.scss";

.dayWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 6.4rem;
  padding: 0 1.6rem;
  background-color: $gray09;
  border: 1px solid $gray06;
  border-radius: 0.3rem;

  .workDayValue {
    color: $gray03;
  
    input {
      display: flex;
      margin-right: 1rem;
      width: 3.8rem;
      height: 3.2rem;
      text-align: center;
      color: $grayConflict;
    }
  }

  &.active {
    background-color: $white;
    border: 1px solid $gray05;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    input {
      color: $darkBlue10
    }
  }

  .toggle {
    margin-right: 2.2rem;
  
    .toggleLabel {
      &:before {
        background-color: $gray05;
      }
    }
  }
}

.dayText {
  width: 8rem;
  margin-right: 4.4rem;
  color: $darkBlue10;
  font-weight: 600;
}

.dateText {
  margin-right: 6.4rem;
}