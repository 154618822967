@import "~component-library/dist/styles/constants.scss";
.crew {
  width: 100%;
  margin-bottom: 2.5rem;
}

.title {
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 600;
  color: $darkBlue11;
  display: flex;
  align-items: center;
  height: 3rem;

  span {
    font-weight: 400;
  }
}

.name {
  display: flex;
  flex-direction: row;
  flex: 1;
  font-weight: 600;
  color: $boomerangBlue;
  font-size: 1.8rem;
  text-align: left;

  a {
    text-align: left;
  }
}

.crewMember {
  display: flex;
  justify-content: space-between;
  border: 1px solid $gray06;
  border-bottom: none;
  padding: 0.8rem;

  &:nth-child(odd) {
    background-color: $gray08;
  }

  &:last-child {
    border-bottom: 1px solid $gray06;
  }
}

.hours {
  display: flex;
  align-items: center;
  font-size: 2.4rem;

  &.small {
    font-size: 2.2rem;
  }
}

.noResults {
  font-weight: 600;
}

.details {
  flex: 1;
}

.sheetWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
