@import "~component-library/dist/styles/constants.scss";


.wrapper {
  border-radius: .4rem;
  border: .1rem solid $gray05;
  margin-bottom: 1.6rem;
  padding: 1rem;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.zebrify {
  &:nth-child(odd) {
    background-color: $gray09;
  }
}