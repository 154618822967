@import "~component-library/dist/styles/constants.scss";

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  height: 4.8rem;
}

.noteCount {
  color: $grayConflict;
  margin-right: 1.5rem;
  margin-left: 0.8rem;
}

.selectField {
  margin-right: 0.8rem;
}