@import "~component-library/dist/styles/constants.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerContent {
  margin-right: 1.5rem;
}

.title {
  display: flex;
  align-items: center;
  color: $darkBlue10;
  font-size: 2rem;
  font-weight: 600;

  span {
    color: $darkBlue11;
    font-weight: 400;
    vertical-align: text-top;
  }
}

.noResults {
  font-weight: 600;
}