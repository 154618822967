.numberSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  @media print {
    margin: 1rem 0;
  }
}

.numbers {
  margin: 1rem;
}