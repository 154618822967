@import "~component-library/dist/styles/constants.scss";

.text {
  color: $gray02;
  font-weight: 600;
}

.updatedText {
  color: $gray03;
  font-size: 1.2rem;
}

.statusPill {
  width: 6.4rem;
  padding: 0.5rem 0.7rem;
  font-size: 0.9rem;
  color: $white;
  text-transform: uppercase;
  text-align: center;
  border-radius: 1.2rem;
  line-height: 1;
  font-weight: 600;
}

.avatarIcon {
  margin-right: 0.8rem;
}