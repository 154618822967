@import "~component-library/dist/styles/constants.scss";

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.title {
  font-size: 2.25rem;
  font-weight: 600;
}

.subtitle {
  font-size: 1.6rem;
  font-weight: 600;
  padding: 2rem 0;
}

.header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.background {
  background-color: $gray09;
  margin-left: -4rem;
  margin-right: -4rem;
  padding: 2rem 4rem;
  margin-top: 2rem;
}
