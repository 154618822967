@import "~component-library/dist/styles/constants.scss";

.imageCounter {
  position: absolute;
  top: 0;
  right: -0.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.4rem;
  height: 1.4rem;
  font-size: 0.7rem;
  color: $white;
  background-color: $redOrange;
}

.date {
  color: $gray02;
  line-height: 4rem;
}

.time {
  margin-left: 3rem;
  color: $gray03;
  line-height: 4rem;
}

.projectName {
  color: $darkBlue11;
  font-weight: 600;
  line-height: 2.4rem;
}

.userName {
  color: $darkBlue11;
  font-weight: 400;
}