@import "~component-library/dist/styles/constants.scss";

.returnButton {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 0;
}

.editorWrapper {
  position: relative;
  height: calc(100vh - 13.6rem);
}