@import "~component-library/dist/styles/constants.scss";

.tableTitle {
  color: $gray02;
  font-weight: 600;
  font-size: 2rem;
}

.takeoffsWrapper {
  margin-top: 3.5rem;
  margin-bottom: 5rem;
}

.hr {
  border-bottom-color: $gray09;
}