@import "~component-library/dist/styles/constants.scss";

.header {
  display: flex;
  align-items: center;
  color: $white;
  background-color: $darkBlue11;
  border-radius: 0;
  padding: 0.5rem 2.5rem;
}

.backdrop {
  background-color: $darkBlue11;
  opacity: .8 !important;
}

.titleText {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 600;
}

.modalBody {
  height: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  overflow: hidden;
  padding: 0;
}

.modalContent {
  border-top: 0;
  border-radius: 0.4rem;
  padding: 0;
  height: calc(100vh - 9.6rem);
  display: flex;
  flex-direction: column;
}