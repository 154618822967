@import "~component-library/dist/styles/constants.scss";

.contactWrap {
  position: relative;
  border-radius: 1.2rem;
  background-color: $itsGrayHooray;
  padding: 1.2rem;
  margin: 0.8rem 0.8rem 0.4rem 0;
  max-width: 21.5rem;
  min-width: 21.5rem;
}

.title {
  color: $gray03
}

.menuButton {
  &:focus, &:active, &:hover {
    box-shadow: none;
  }
}

.address {
  margin-top: 0.5rem;
  line-height: 1.3;
}