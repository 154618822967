@import "~component-library/dist/styles/constants.scss";

.noTasks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: bold;
  border-radius: 0.8rem;
  padding: 1rem 2.1rem;
  background-color: $gray09;
  margin-top: 1rem
}

.tasks {
  margin-top: 1rem
}

.toggleButton {
  width: 50%;
  color: $gray03;
  font-weight: 600;
  background-color: $gray06;
  height: 4rem;
  font-size: 1.6rem;
}