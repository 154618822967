@import "~component-library/dist/styles/constants.scss";

.container {
  flex: 1;
  padding: 0 2rem 3.5rem;
}

.formHeader {
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 0.1rem;
  border-bottom-color: $gray09;
  margin-bottom: 2rem;
  margin-right: -2rem;
}

.nameText {
  font-size: 1.6rem;
  color: $boomerangBlue;
}

.formName {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
}

.nameIcon {
  margin-right: 0.5rem;
}

.answerBlock {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom-color: $gray05;
  border-bottom-width: 1px;
}

.header {
  margin-bottom: 1rem;
  font-size: 2rem;
}
.subheader {
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
}

.image {
  width: 35rem;
  height: 35rem;
}

.backIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  background-color: $boomerangBlue;
}

.uploadGrid {
  display: grid;
  column-gap: 2rem;
  row-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 40rem));

  img {
    width: 100%;
  }
}

.address {
  white-space: pre-wrap;
}