@import "~component-library/dist/styles/constants.scss";

.cancelButton {
  margin-top: 0.8rem;
  margin-right: 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
  background-color: $gray09;
}

.headerText {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;

  svg {
    margin-right: 0.8rem;
  }
}

.formRow {
  margin-bottom: 4.8rem;
}

.formRowHalf {
  margin-bottom: 2.4rem;
}

.modalBody {
  padding: 0;
}

.contentWrapper {
  position: relative;
  padding: 2.4rem;
}

.submitButton {
  margin-top: 0;
  font-weight: bold;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2.4rem;
  background-color: $gray09;
}

.prodRatePreview {
  border-radius: 0.4rem;
  padding: 0.9rem 1.6rem;
  background-color: $gray09;

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    color: $darkBlue11;
  }

  span {
    font-size: 2.4rem;
    color: $gray02;
  }
}