@import "~component-library/dist/styles/constants.scss";

.rowContentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5rem;
  border-bottom: 1px solid $rule-light;
  border-left: 1px solid $rule-light;
  background-color: $itsGrayHooray;
}

.rowItemContent {
  display: flex;
  justify-content: flex-start;
  min-width: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bolderText {
  font-weight: 600;
  color: $iGiveUpGray;
}

.columnHeader {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}

.headerWrapper {
  padding-left: 0.5rem;
  display: flex;
}
