@import "~component-library/dist/styles/constants.scss";

.timePicker {
  background-color: $gray05;
  border-radius: 0.5rem;
  margin: 0 1rem;
  padding: 0.2rem;
  border: none;
  text-align: center;
  max-width: 10rem;
}

.timePickerEditable {
  background-color: $white;
  border-radius: 0.5rem;
  margin: 0 1rem;
  padding: 0.2rem;
  border: 1px solid  $gray05;
  text-align: center;
  max-width: 10rem;
}