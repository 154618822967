@import "~component-library/dist/styles/constants.scss";

.printOnlyDate {
  display: none;
  @media print {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;
  }
  h2 {
    color: $grayTableHeader;
    margin-top: 0.5rem;
  }
}

.tasksTitle {
  margin-right: 0.8rem;
  color: $darkBlue10;
  font-size: 3.2rem;
  font-weight: 600;
}

.tasksLength {
  color: $gray03;
}

.reportedNumber {
  margin-right: 0.8rem;
  color: $darkBlue11;
  font-weight: 700;
  font-size: 2rem;
}

.earnedNumber {
  margin-right: 0.8rem;
  color: $green;
  font-weight: 700;
  font-size: 2rem;
}

.reportedText {
  color: $grayConflict;
}
