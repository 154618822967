@import "~component-library/dist/styles/constants.scss";

.sectionTitle {
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-size: 2rem;
  color: $darkBlue11;
}

.albumName {
  margin-right: 1.6rem;
  color: $darkBlue11;
  display: flex;
  align-items: center;
}

.photoCount {
  color: $gray03;
}

.emptyWrapper {
  margin-top: 8rem;
}

.pinIcon {
  width: 1.6rem;
  height: 1.6rem;
  color: $gray02;
}

.pinIconList {
  width: 1.6rem;
  height: 1.6rem;
  color: $gray02;
  margin-right: .5rem;
}

.allPhotosTitle {
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
  color: $darkBlue11;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 2rem 0;
  padding: 0;
}

.sliderSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 70vw;
  max-height: 70vh;
}

.listContainer {
  min-width: 110rem;
}
