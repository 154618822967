@import "~component-library/dist/styles/constants.scss";

.button {
  color: $gray03;

  &:hover,
  &:focus {
    color: $boomerangBlue;
  }
}

.activeView {
  color: $darkBlue11;
  border-radius: 0;
  border-bottom: 2px solid $darkBlue11;

  &:hover,
  &:focus {
    color: $darkBlue11;
  }
}