@import "~component-library/dist/styles/constants.scss";

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  height: 4.8rem;
}

.noteCount {
  color: $grayConflict;
  margin-right: 1.5rem;
  margin-left: 0.8rem;
}

.hidePrint {
  @media print {
    display: none;
  }
}

.toggleAll {
  padding: 0;
  margin-bottom: 1.6rem;
}

.imageCounter {
  position: absolute;
  top: 0;
  right: -0.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.4rem;
  height: 1.4rem;
  font-size: 0.7rem;
  color: $white;
  background-color: $redOrange;
}
