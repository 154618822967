@import "~component-library/dist/styles/constants.scss";

.link {
  color: $boomerangBlue;
  
  svg {
    margin-right: 0.8rem;
  }
}

.version {
  margin-left: 0.8rem;
  font-size: 1.4rem;
  color: $gray02;
}