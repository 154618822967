@import "~component-library/dist/styles/constants.scss";

.tableTitle {
  color: $gray02;
  font-weight: 600;
  font-size: 2rem;
}

.fieldLogs {
  padding-top: 3rem;
  border-top: 1px solid $gray08;
}