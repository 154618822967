@import "~component-library/dist/styles/constants.scss";

.equipment {
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  height: 3rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: $darkBlue11;

  span {
    font-weight: 400;
  }
}

.equipmentName {
  width: 21rem;
}

.equipID {
  margin-right: 1rem;
  font-weight: 600;
  color: $boomerangBlue;
  font-size: 1.8rem;
}

.singleEquipment {
  display: flex;
  align-items: center;
  border: 1px solid $gray06;
  border-bottom: none;
  padding: 0.8rem;

  &:nth-child(odd) {
    background-color: $gray08;
  }
}

.hours {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
}

.noResults {
  font-weight: 600;
}

.text-limiter {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100%);

  @media (max-width: 1200px) {
    width: calc(100% - 4rem);
  }
}
