@import "~component-library/dist/styles/constants.scss";

.projectsWrapper {
  border-top: 1px solid $gray06;
  padding-top: 4rem;
  margin-top: 4rem;
}

.projectCard {
  margin: 1rem;
  border-radius: 0.8rem;
  border: 2px solid $gray06;
  padding: 1.5rem;
  max-width: 22rem;
}

.cardIcon {
  position: absolute;
  right: 1rem;
  bottom: 1.5rem;
}