@import "~component-library/dist/styles/constants.scss";

.container {
  display: flex;
  flex-direction: row;
}

.photoSectionWrapper {
  margin-right: 2rem;
  min-width: 50rem;
  height: calc(100vh - 144px);
  padding-right: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.commentSectionWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 144px);
  overflow-x: hidden;
  overflow-y: auto;
}

.photo {
  background-color: $gray08;
  width: 48rem;
  height: 48rem;
  overflow: hidden;
  position: relative;

  .photoFront {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .photoBack {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(2.5rem);
    opacity: 85%;
  }
}

.fakeHeaderBtn {
  display: flex;
  align-items: center;
  color: $gray02;
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.subHeaderWrapper {
  display: flex;
  flex: 1
}

.subHeaderText {
  font-size: 1.6rem;
  margin-right: 0.8rem;
  color: $darkBlue11;
}

.pinIcon {
  width: 1.6rem;
  height: 1.6rem;
  color: $boomerangBlue;
}

.mapWrapper {
  width: 100%;
  height: 24rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.noMap {
  background-image: url("../../../../assets/bg-field.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 20rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0.4rem;
}

.mapTitle {
  font-size: 2.4rem;
  padding: 0 2.5rem;
  color: $white;
}

.thumbTitle {
  font-size: 1.7rem;
  font-weight: 600;
}

.thumbSection {
  display: flex;
  flex-direction: row;
  width: 48rem;
}
