@import "~component-library/dist/styles/constants.scss";

.submit {
  align-self: center;
  margin-top: 0.8rem;
  color: #fff;
}

.logo {
  margin-bottom: 2rem;
  width: 17.2rem;
}

.footer {
  display: flex;
  justify-content: center;
}

.login-form-wrap {
  min-height: 100vh;
  margin-top: -4.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.bgWrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  overflow: hidden;

  svg {
    margin-left: -5rem;
    bottom: 0;
  }
}

.loginForm {
  max-width: 95%;
  margin: 0 auto 11.8rem;
  width: 44rem;
  border-radius: 0.4rem;
  box-shadow: $shadow;
  border-top: 0.8rem solid $boomerangBlue;
  background-color: #fff;
  padding: 4.2rem 3.2rem 2rem;
}

.contact {
  width: 24rem !important;
  min-width: 33% !important;

  @media (min-width: 768px) {
    min-width: 25% !important;
  }
}