@import "~component-library/dist/styles/constants.scss";

.photosTitle {
  margin-bottom: 0;
  margin-right: 0.4rem;
  color: $gray02;
  font-size: 1.6rem;
  font-weight: 600;
}

.photoCount {
  font-style: italic;
  font-size: 1.6rem;
  color: $gray02;
}

.photoGrid {
  display: grid;
  grid-template-columns: 4.8rem 4.8rem 4rem 4rem 4rem;
  grid-template-rows: 4rem 4rem;
  column-gap: 0.8rem;
  row-gap: 0.8rem;
}

.largeImg {
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
}

.smallImg {
  width: 4rem;
  height: 4rem;
  border-radius: 0.8rem;
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
}