.photos {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.photo {
  width: 17.5rem;
  height: 17.5rem;
  object-fit: cover;

  @media print {
    width: 8rem;
    height: 8rem;
  }
}