@import "~component-library/dist/styles/constants.scss";

.wrapper {
  position: relative;
  min-height: 20rem;
}

.sectionToggleButton {
  background-color: transparent;
  border-color: transparent;
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  &:active, &:hover, &:focus {
    background-color: transparent;
    border-color: $gray05;
    box-shadow: none;
    color: $gray03;
  }
}

.headerText {
  display: flex;
  align-items: center;
  margin-top: .4rem;
  color: $gray02;
  font-weight: 600;
}