@import "~component-library/dist/styles/constants.scss";

.pill {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  font-size: 1.2rem;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  color: $white;
  border-radius: 0.4rem;
}

.warn  {
  background-color: $redOrange;
}

.noWarn {
  background-color: $green;
}