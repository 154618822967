@import "~component-library/dist/styles/constants.scss";

.reportDateRange {
  color: $darkBlue10;
  font-weight: 600;
  font-size: 2.4rem;
}

.reportWrapper {
  @media (min-width: 1400px) {
    display: flex;
  }
}