@import "~component-library/dist/styles/constants.scss";

.totalBase {
  position: absolute;
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 3rem;

  sup {
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    font-size: 1.6rem;
  }
}

.percentageText {
  position: relative;
  color: #0071ce;
  font-size: 4.8rem;
  font-weight: 600;

  &.mediumText {
    font-size: 4rem;
  }

  &.smallText {
    font-size: 2.8rem;
  }

  &.xsText {
    font-size: 1.4rem;
    font-weight: 700;
  }
}

.subText {
  color: #0071ce;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.tinyPercent {
  position:absolute;
  font-size: 0.5rem;
  top: -0.5rem;
}