@import "~component-library/dist/styles/constants.scss";

.sidebar {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 4.8rem;
  background-color: $indigo;
  height: calc(100vh - 4.8rem);
  min-width: 28rem;
}

.mapWrapper {
  height: 30.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;

  @media (min-width: 625px) {
    width: 28rem;
  }
}

.thumbnail {
  width: 100%;
  height: auto;
  z-index: -1;

  @media (min-width: 625px) {
    width: auto;
    height: 30.4rem;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 4rem;
  height: 4rem;
  border-radius: 0.4rem;
  background-color: $white;
}

.maxIcon {
  color: $grayConflict;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.mapTitle {
  font-size: 2.4rem;
  padding: 0 2.5rem;
  color: $white;
}

.setupLink {
  color: $orange;

  &:hover,
  &:focus {
    color: $white;
  }
}

.teamHeader {
  padding: 1.6rem 1.4rem;
  font-size: 1.6rem;
  font-weight: bold;
  color: $white;
  background-color: $darkBlue11;
}

.itemsWrapper {
  padding: 0 1.6rem;
}

.foremanSelect {
  width: 24.5rem;
}

.loadWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255,255,255,0.75);
  z-index: 99;
}

.scrollWrap {
  height: calc(100vh - 35.2rem);
}

.projectTeam {
  padding-bottom: 3rem;
}

.scroll {
  flex: 1;
}

.noMap {
  background-image: url("../../../assets/bg-field.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 35rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}