@import "~component-library/dist/styles/constants.scss";

.largeHeaderText {
    font-size: 2rem;
    color: $darkBlue10;
    font-weight: 600;
  }

.headerText {
    font-size: 1.6rem;
    color: $darkBlue10;
    font-weight: 600;
  }

.foremanHeaderContent {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  padding: 1rem;
  font-size: 1.6rem;
  color: $darkBlue10;
  font-weight: 600;
  margin-bottom: 0rem;
}

.selectAll {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.wrapperNoPadding {
  border-radius: .4rem;
  border: .1rem solid $gray06;
  margin-bottom: 1.6rem;
}

.borderWrapper {
  border: .1rem solid $gray06;
}

.singleEquipment {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  justify-content: space-between;
  height: 4.8rem;
  border-top: .1rem solid $gray06;
  &:nth-child(odd) {
    background-color: $gray09;
  }
}

.name {
  font-weight: 600;
  color: $boomerangBlue;
  font-size: 1.6rem;
  text-align: left;
}
