@import "~component-library/dist/styles/constants.scss";

.number {
  font-size: 3rem;
  font-weight: 800;
  width: 5rem;
  padding: 0 1rem;
}

.numberLabel {
  font-size: 1.6rem;
  font-weight: 600;
}

.numberSection  {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  gap: 1rem;
}

.footerStats {
  text-align: right;
  justify-content: flex-end;
  margin-top: 4rem;
}