@import "~component-library/dist/styles/constants.scss";

.phaseToggleLabel {
  color: $gray02;

  &:hover,
  &:focus {
    color: $gray02;
  }
}

.badgeWrapper {
  min-width: 7.5rem;
}

.toggle {
  &::before {
    border: 1px solid $gray05;
  }
  &::after {
    border: 1px solid $gray05;
  }
}

.flex1 {
  flex: 1;
}