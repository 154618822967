@import "~component-library/dist/styles/constants.scss";

.gantt {
  align-self: stretch;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 8.8rem);
}

.dateRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
}

.dateCard {
  background-color: $gray09;
  padding: 1rem 1.5rem;
  min-height: 8.5rem;
  border-radius: 0.5rem;

  & .date {
    font-size: 3.2rem;
    line-height: 3.04rem;

  }
  & .title {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 700;
    color:  $gray02;
  }
}

.sectionTitleWrapper {
  display: flex;
  flex-direction: row;  
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.sectionTitle {
  font-size: 2rem;
  font-weight: 600;
  color: $gray02;
} 

.sectionTitleAdjust {
  margin-left: -2.6rem;
} 

.editFormHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerButtonText{
  color: $gray02;
  padding: 0 0.5rem;
  font-size: 1.6rem;
}

.editDatesButton {
  background-color: transparent;
  border-color: transparent;
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  &:active, &:hover, &:focus {
    background-color: transparent;
    border-color: $gray05;
    box-shadow: none;
    color: $gray03;
  }
}