@import "~component-library/dist/styles/constants.scss";

.chart-title {
  font-size: 1.6rem;
}

.firstJPRow {
  min-height: 19rem;
}

.otherJPRow {
  min-height: 21.8rem;
}

.header {
  color: #797c84;
  font-size: 1.6rem;
  font-weight: 600;
}


.job-title {
  font-size: 1.6rem;
}

.job-avg-wrapper {
  font-size: 2.4rem;
  font-weight: 500;
  color: $gray;
  flex-grow: 1;
  display: flex;
  align-items: center;
  align-self: center;

  sup {
    font-size: 100%;
    top: -1em;
  }
}

.job-avg {
  font-size: 5.12rem;
  color: $boomerangBlue;
}

.bigNumber {
  font-size: 1.8rem;
  .job-avg {
    font-size: 3.84rem;
  }
}

.reallyBigNumber {
  font-size: 1.6rem;
  .job-avg {
    font-size: 3.2rem;
  }
}
