@import "~component-library/dist/styles/constants.scss";

.tasksWrapper {
  min-width: 35rem;
  max-height: 20rem;
}

.title {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
}

.code {
  line-height: 1;
  color: $darkBlue10;
  span {
    text-decoration: none;
  }
}

.task {
  margin-bottom: 0.8rem;
  border-radius: 0.8rem;
  background-color: $gray08;
}

.taskName {
  padding: 0;
  font-size: 1.6rem;
  font-weight: 600;
  color: $boomerangBlue;
  span {
    text-decoration: none;
  }
}

.taskLink {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1.6rem;
  border-radius: 0.8rem;

  &:hover,
  &:focus {
    background-color: $blueTenPercent;
  }
}