@import "~component-library/dist/styles/constants.scss";

.container {
  margin-top: 1.6rem;
}
.taskHeader {
  width: 100%;
  min-width: 100rem;
  background-color: $white;
  border-radius: 1rem;
  padding-left: 2rem;
  height: 4.4rem;
  font-size: 1.6rem;
  color: $darkBlue11;
  font-weight: 600;
  display: flex;
  align-items: center;
  border: 1px solid #e9e9e9;
}
.taskNum {
  width: 8rem; 
}
.numTasks {
  color: $gray03;
  font-size: 1.2rem;
  margin-left: 1.6rem;
}
.taskItem {
  display: flex;
  flex: 1;
  margin-left: 1rem;
  padding-left: 2rem;
  height: 4.2rem;
  background-color: $white;
  border-radius: 1rem;
  border: 1px solid #e9e9e9;
}

.wrapper {
  text-align: right;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.wrapperSmall {
  text-align: right;
  width: 9.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}