@import "~component-library/dist/styles/constants.scss";

.addButton {
  border-style: dashed;
  width: 7.2rem;
  min-height: 7.2rem;
  padding: 0.9rem;
  font-size: 1.3rem;
  background-color: #f1f0f0;
  &:hover {
    background-color: #797c84;
    border: 1px dashed #797c84;
  }
};