@import "~component-library/dist/styles/constants.scss";

.submittal-row {
  border-top: 1px solid $rule-light;
  vertical-align: top;
  margin-top: 2.5rem;
  .sub-cell {
    padding: 1rem;
    vertical-align: top;
  }
  [class*="form-group"] {
    margin-bottom: 0;
  }
  label {
    display: none;
  }
}
