@import "~component-library/dist/styles/constants.scss";

.subtext {
  font-size: 1.6rem;
  color: $gray03;
  line-height: 1;
}

.subtaskName {
  max-width: 30rem;
  font-weight: 600;
  font-size: 1.6rem;
  color: $boomerangBlue;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
}

.costCode {
  font-size: 1.8rem;
}

.overUnder {
  font-size: 1.6rem;
  color: $green;
  width: 15rem;
  display: flex;
  justify-content: flex-end;

  &.zero {
    color: $darkBlue10;
  }

  &.overage {
    color: $redOrange;
  }
}

.percentage {
  font-weight: 700;
}

.reported {
  line-height: 1;
}

.estimate {
  font-weight: 700;
}

.remaining {
  color: $green;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
}

.earned {
  color: $green;
  font-size: 1.6rem;
  font-weight: 700;
}

.warn {
  color: $redOrange;
}

.wrapper {
  text-align: right;
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 400;
  color: $darkBlue10;
}

.wrapperSmall {
  text-align: right;
  width: 9.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.percWrap {
  width: 7.5rem;
  text-align: right;
}
