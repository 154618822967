@import "~component-library/dist/styles/constants.scss";

.weatherBlock {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.5rem;
  height: 5.6rem;
  border-radius: 0.3rem;
  border: 1px solid $gray05;
  background-color: $white;
  text-decoration: none !important;
  min-width: 15rem;

  @media (max-width: 501px) {
    margin-top: 1.5rem;
  }

  &:hover,
  &:focus {
    border: 1px solid $darkBlue11;
    background-color: $white !important;
  }
}

.tempsBlock {
  display: flex;
  align-items: center;
  flex: 1;
}

.value {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2rem;
  color: $darkBlue11;
}

.description {
  font-size: 1.2rem;
  color: $blue02;
  text-align: center;
  max-width: 10rem;
  line-height: 1;
}

.precip {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid $gray05;

  text-align: center;
}

.weatherBlockMulti {
  line-height: 1.25;
}

.multi {
  font-weight: 600;
  color: $darkBlue10;
}