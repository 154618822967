@import "~component-library/dist/styles/constants.scss";

.task {
  width: 100%;
  background-color: $gray06;
  border-radius: 0.4rem;
  color: $gray02;
  text-align: left;

  &:hover,
  &:focus {
    color: $gray02;
    background-color: $gray06 !important;
    text-decoration: none;
    cursor: pointer;
  }

  &.taskOpen {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: $gray06;
  }
}

.taskName {
  max-width: 50rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.taskRightContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.taskTime {
  width: 6.8rem;
  text-align: right;
}

.loader {
  top: 0;
}