@import "~component-library/dist/styles/constants.scss";

.icon {
  margin-right: 0.7rem;
}

.clockWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  background-color: $gray08;
  border-radius: 1.2rem;
  padding: 1rem 0;
  padding: 0 1.5rem;
  margin-bottom: 1rem;
}

.times {
  padding: 1rem 0;
  color: $gray02;
}

.timePicker {
  font-size: 3rem;
  max-width: none;
  width: 90%;
  border: none;
  color: $gray02;
  background-color: transparent;
}

.innerWrapper {
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 0.8rem;
  padding-left: 1.5rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.locked {
    background-color: $gray05;
  }
}

.timesTitle {
  display: block;
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: 600;
}

.location {
  font-size: 1.4rem;
  font-style: italic;
  color: $iGiveUpGray;
}
