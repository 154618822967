@import "~component-library/dist/styles/constants.scss";

.hours {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
}

.hours {
  font-size: 2.4rem;
  color: $gray02;
}

.sheetWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timeWrapper {
  display: flex;
  align-items: center;
  width: 9rem;
  margin-right: 0.8rem;
  font-size: 1.4rem;
}
