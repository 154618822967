@import "~component-library/dist/styles/constants.scss";
.title {
  margin-bottom: 2.4rem;
  color: $gray02;
  font-weight: 600;
}

.folderLink {
  display: flex;
  align-items: center;
  margin-right: 1.6rem;
  margin-bottom: 0.8rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  color: $boomerangBlue;
  background-color: $backgroundBlue;

  svg {
    margin-right: 0.8rem;
  }
}

.customFolder {
  color: $teal07;
  background-color: $teal06;
}