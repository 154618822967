@import "~component-library/dist/styles/constants.scss";

.submittals-header {
  border-bottom: 1px solid $rule-light;
  padding-bottom: 1.6rem;

  h3 {
    font-size: 2rem;
    color: $dark-text;
  }
}
