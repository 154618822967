@import "~component-library/dist/styles/constants.scss";

.viewToggle {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-left: 1.6rem;

  button {
    width: 3.2rem;
    height: 3.2rem;
    border: 1px solid $gray05;
  }
}

.active {
  background-color: $boomerangBlue;
}
