@import "~component-library/dist/styles/constants.scss";

.notesTitle {
  margin-top: 3.2rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue10;
}

.notesSidebarWrapper {
  margin-left: 1rem;
  min-width: 39.2rem;
}