@import "~component-library/dist/styles/constants.scss";

//SHARED
.rowItem {
  margin: 0 1rem;
  width: 15rem;
  color: $gray02;
}

.rowHover {
  &:hover {
    background-color: $blue06 !important;
  }
}

.rowItemSmall {
  margin: 0 1rem;
  width: 1.6rem;
  color: $gray02;
}

.date {
  font-style: italic;
}

.listContainer {
  min-width: 110rem;
}

.pinIcon {
  width: 1.6rem;
  height: 1.6rem;
  color: $boomerangBlue;
}

.unpinIcon {
  width: 1.6rem;
  height: 1.6rem;
  color: $grayConflict;
}

.pinDeleteWrap {
  width: 6.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 14.4rem;
}

//PHOTO ROW STYLING
.photoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.2rem;
  img {
    max-height: 48px;
    max-width: 48px;
    object-fit: contain;
  }
  &:nth-child(even) {
    background-color: $gray09;
  }
}

.filename {
  flex: 1;
  font-size: 1.6rem;
  color: $boomerangBlue;
}

//HEADER STYLING
.headerWrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 1.2rem;
  .unflexHeaders {
    display: flex;
    justify-content: flex-end;
  }
  .headerItem {
    color: $gray03;
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
    width: 15rem;
    margin: 0 1rem;
  }
}

.listTitle {
  display: flex;
  align-items: start;
  text-transform: none !important;
  font-weight: 600 !important;
  min-width: 24rem;
  flex: 1;
  .titleIcon {
    height: 1.6rem;
    width: 1.6rem;
    color: $iGiveUpGray;
    margin-right: 0.5rem;
  }
  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    color: $darkBlue11;
    margin-bottom: 0rem !important;
  }
}

.invsibleCol {
  min-width: 6.8rem;
}

.invsibleColAlbums {
  min-width: 10.2rem;
}

.invisibleSmall {
  min-width: 1.6rem;
}

//ALBUM STYLING
.accordionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.2rem;
  // border-bottom: 1px solid $gray05;
}

.albumName {
  flex: 1;
  font-size: 1.6rem;
  color: $darkBlue11;
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
}
