@import "~component-library/dist/styles/constants.scss";

.submitButton {
  margin-top: 0;
  font-weight: bold;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2.4rem;
  background-color: $gray09;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
  background-color: $gray09;
}

.headerText {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;

  svg {
    margin-right: 0.8rem;
  }
}