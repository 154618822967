@import "~component-library/dist/styles/constants.scss";

.foremen {
  height: 16.4rem;
  padding-left: 1.2rem;
}

.foremenWrap {
  margin-right: -1.2rem;
  margin-left: -1.2rem;
}

.foremenFooter {
  border-top: 1px solid $gray05;
  margin: 0 -1.2rem;
  padding: 1rem 1.2rem 0.4rem;
}

.foremenHeader {
  border-bottom: 1px solid $gray05;
  padding-bottom: 1rem;
}

.foremenModal {
  width: 28rem;
  font-size: 1.6rem;
}

.icon {
  margin-right: 0.8rem;
}

.overlayButton {
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background-color: transparent !important;
  }
}