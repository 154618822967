@import "~component-library/dist/styles/constants.scss";

.milestonesRow {
  position: relative;
  display: flex;
  align-items: flex-start;
  min-width: 29rem;
  min-height: 14.4rem;
  margin-bottom: 1.5rem;
}

.milestone {
  padding: 1rem 1rem;
  width: 13.5rem;
  height: 14.4rem;
  background-color: $indigo;
  margin-right: 1rem;
  color: $white;
  border-radius: 0.4rem;
}

.date {
  font-weight: 600;
  margin-bottom: 1rem;
}

.description {
  line-height: 1.6rem;
  text-overflow: ellipsis;
  max-height: 7rem;
}

.statsTitle {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  color: $darkBlue10;
  font-size: 1.6rem;
  font-weight: 600;

  span {
    color: $darkBlue11;
    font-weight: 400;
    vertical-align: text-top;
  }
}

.error {
  max-width: 30rem;
  word-break: break-all;
}