@import "~component-library/dist/styles/constants.scss";

.title {
  font-size: 2rem;
  font-weight: 600;
}

.header {
  color: $white;
  background-color: $darkBlue11;
  border-radius: 0;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.initials {
  font-size: 1.6rem;
  font-weight: 600;
  color: $white;
  text-transform: uppercase;
  width: 4rem;
  height: 4rem;
  border-radius: 0.4rem;
  margin-right: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
}

.equipmentName {
  font-weight: 600;
}

.driverName,
.equipmentName {
  color: $darkBlue11;
}

.subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem;
  background-color: $gray09;
}

.body {
  padding: 3.8rem 6rem 3.6rem 5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 0;
}

.imageWrapper {
  flex: 0 0 auto;
  width: 20%;
  min-width: 20rem;
  margin-bottom: 2rem;
}

.image {
  width: 100%;
  border-radius: 0.8rem;
}

.slide {
  max-height: 70rem;

  img {
    width: 100%;
  }
}