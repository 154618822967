@import "~component-library/dist/styles/constants.scss";

.avatarButton {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0 0.5rem;
}

.avatarLabel {
  color: $boomerangBlue;
  padding-left: 1rem;
}