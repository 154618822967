@import "~component-library/dist/styles/constants.scss";

.modalTitle {
  font-size: 2rem;
  font-weight: 600;
  color: $darkBlue11;
}

.taskTableWrapper {
  max-height: 60rem;
  overflow: auto;
}

.tasksTableHeader {
  color: $grayTableHeader;
  font-weight: 600;
  padding: 0;
}