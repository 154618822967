@import "~component-library/dist/styles/constants.scss";

.poiName {
  font-size: 2.4rem;
  color: $darkBlue11;
  font-weight: 600;
}

.poiType {
  font-size: 1.6rem;
  color: $gray03;
  text-transform: uppercase;
  font-weight: 600;
}

.gridWrapper {
  width: 80rem;
  margin: 0px auto;
}

.sectionToggleButton {
  background-color: transparent;
  border-color: transparent;
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  &:active,
  &:hover,
  &:focus {
    background-color: transparent;
    border-color: $gray05;
    box-shadow: none;
    color: $gray03;
  }
}

.circle {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin-right: 0.8rem;
}

.searchFilter {
  width: 30rem;
  margin-bottom: 1.2rem;
  align-self: flex-start;
}