@import "~component-library/dist/styles/constants.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid $gray05;
  border-radius: 4px;
  padding: 16px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.title {
  font-weight: 600;
}

.allSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.allText {
  color: $gray03;
  font-size: 14px;
}
