@import "~component-library/dist/styles/constants.scss";

.sectionTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-size: 2rem;
  color: $darkBlue11;
}

.photoGrid {
  display: grid;
  column-gap: 0.2rem;
  row-gap: 0.2rem;
  margin-bottom: 2rem;

  .imgWrapper {
    width: 100%;
    background-color: $gray06;

    img {
      object-fit: cover;
    }
  }
}

.largeGrid {
  grid-template-columns: repeat(auto-fill, minmax(18.9rem, 1fr));

  .imgWrapper {
    height: 18.4rem;
  }
}

.mediumGrid {
  grid-template-columns: repeat(auto-fill, minmax(15.7rem, 1fr));

  .imgWrapper {
    height: 15.7rem;
  }
}

.smallGrid {
  grid-template-columns: repeat(auto-fill, minmax(15.7rem, 1fr));

  .imgWrapper {
    height: 15.7rem;
  }
}

.pinIcon {
  width: 1.6rem;
  height: 1.6rem;
  color: $gray02;
}
