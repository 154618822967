@import "~component-library/dist/styles/constants.scss";

.headerNameText {
  font-weight: 600;
  color: $nearBlack;
  font-size: 2rem;
  margin-right: 1rem;
}

.roleText {
  font-size: 1.6rem;
  color: $gray03;
  margin-bottom: 0.25rem;
}

.filterWrap {
  width: 30rem;
}