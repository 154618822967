@import "~component-library/dist/styles/constants.scss";

.attachment-navbar {
  position: absolute;
  top: 0;
  right: 0.5rem;

  .attachment-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      color: #2e2e2e
  }
}

.attachment-hover-icons {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    display: none;
  }
}

.attachment-wrapper {
  background-color: #fff;
  &:hover,
  &:focus {
    .attachment-hover-icons {
      display: flex;
    }
  }
}

.attachment-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.4rem;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  border-radius: 50%;
  background-color: #e8f0f9;
}

.submenu-wrapper {
  display: none;
  position: absolute;
  top: 0.3rem;
  left: 0;
  width: 12.5rem;
  height: 10rem;
  padding-top: 1rem;
  font-size: 1.2rem;
  border: 1px solid $gray05;
  background-color: $white;
  border-radius: 0.4rem;
  z-index: 10;
}