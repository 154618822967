@import "~component-library/dist/styles/constants.scss";
.supplierWrapper {
  flex: 1;
  margin-top: 2rem;
}

.addStyles {
  border-style: dashed;
  width: 9rem;
  min-height: 9rem;
  padding: 0.9rem;
  font-size: 1.3rem;
}