@import "~component-library/dist/styles/constants.scss";

.warningBg {
  td {
    background-color: $red01;
  }
}

.openTaskHeader {
  td {
    font-weight: 600;
    background-color: $grayBlue;
  }
}