@import "~component-library/dist/styles/constants.scss";

// Task
.task {
  margin-bottom: 1.5rem;
  border: 1px solid $gray05;
  border-radius: 0.4rem;
}

// Header
.tasksHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}

.title {
  font-size: 1.6rem;
  font-weight: 600;
  color: $boomerangBlue;
  text-align: left;
  margin-bottom: 0;
}

.phase {
  color: $gray02;
  font-size: 1.2rem;
  text-align: left;
}

.actionWrapper {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  margin-right: 2rem;
  border-right: 1px solid #D3D3D3;
}

.totalHours {
  margin-right: 1rem;
  font-size: 2.4rem;
  color: $gray02;
}

.countIndicator {
  position: absolute;
  top: 0;
  right: -0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  color: $white;
  background-color: $redOrange;
  font-weight: 600;
}

// Subheader
.taskSubheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: $gray06;
  font-weight: 600;
  color: $gray02;
}

.rate {
  font-size: 1.4rem;
  color: $gray02;

  @media (min-width: 768px) {
    width: 30rem;
  }
}

.input {
  margin: 0 0.5rem;
  border-radius: 0.4rem;
  width: 7rem;
  height: 2.6rem;
  border: 1px solid $gray05;
  text-align: center;
  color: $gray02;
  background-color: $gray05;
}

.entryHours {
  color: $gray02;
  font-weight: 700;
}

.qtyInput {
  margin: 0 0.5rem;
  border-radius: 0.4rem;
  width: 7rem;
  height: 2.6rem;
  border: 1px solid $gray05;
  color: $gray03;
  text-align: right;
}

.pill {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: $white;
  border-radius: 0.4rem;
  background-color: $green;
}

// Time Entries
.entries {
  padding: 1.5rem;
}

.timeEntry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  border-radius: 0.4rem;
  padding: 1rem;
  background-color: $gray08;
}

.entryName {
  width: 20rem;
  font-weight: 600;
  line-height: 1.7rem;
  color: $boomerangBlue;
}

.select {
  max-width: 27.5rem;
}