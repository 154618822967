@import "~component-library/dist/styles/constants.scss";

.file {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1.5rem;
}

.simplifiedTitle {
  font-weight: 600;
}

.pillBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.2rem;
  color: $boomerangBlue;
  font-weight: 700;
  border-radius: 1.6rem;
  background-color: $blueTenPercent;
}

.uploadWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 35rem;
  height: 4rem;
}