@import "~component-library/dist/styles/constants.scss";

.largeHeaderText {
    font-size: 2rem;
    color: $darkBlue10;
    font-weight: 600;
  }

  .name {
    font-weight: 600;
    color: $boomerangBlue;
    font-size: 1.6rem;
    text-align: left;
  }

.headerText {
    font-size: 1.6rem;
    color: $darkBlue10;
    font-weight: 600;
  }

.headerContent {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;
  height: 4.8rem;
}

