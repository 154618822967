@import "~component-library/dist/styles/constants.scss";

.nameText {
  font-weight: 600;
  color: $gray02;
  margin-left: 1rem;
}

.recentProject {
  font-weight: 600;
  color: $gray02;
}