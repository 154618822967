@import "~component-library/dist/styles/constants.scss";

.header {
  font-weight: 600;
}

.map-section {
  padding-right: 0;
}

.map {
  flex: 1;
  width: 100%;
  height: 70rem;

  @media (min-width: 1200px) {
    height: 100%;
  }
}

.mapContainer {
  min-height: 70rem;
}

.list {
  overflow: hidden;
  height: 70rem;
  border: 1px solid $gray06;
}

.listItem {
  padding: 1rem;
  min-height: 8rem;
  border-bottom: 1px solid $gray06;

  &.infoWindow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    min-height: 6rem;
  }
}

.badgeWrapper {
  display: inline-block;
  width: 3rem;
  margin-right: 0.8rem;
}

.projectName {
  max-width: 21.5rem;
  font-weight: 600;
  color: $iGiveUpGray;
}

.jobNumber {
  color: $indigo;
}

.projectCategory {
  background-color: $boomerangBlue;
  color: $white;
  border-radius: 2rem;
  padding: 0.25rem 1rem;
}