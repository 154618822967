@import "~component-library/dist/styles/constants.scss";

.noteTitle {
  margin-bottom: 0;
  margin-right: 0.4rem;
  color: $gray02;
  font-size: 1.6rem;
  font-weight: 600;
}

.noteCount {
  font-style: italic;
  font-size: 1.6rem;
  color: $gray02;
}