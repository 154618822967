@import "~component-library/dist/styles/constants.scss";

.contacts {
  display: flex;
  flex-wrap: wrap;
}

.header {
  border-bottom: 1px solid $gray05;
}

.categoryBubble {
  border-radius: 1.6rem;
  height: 3.2rem;
  margin-left: 0.8rem;
  background-color: $blueTenPercent;
  color: $boomerangBlue;
  text-transform: capitalize;
  font-size: 1.3rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;

  &.archived {
    color: $gray03;
    background-color: $gray06;
  }
}

.addButton {
  border-style: dashed;
  border-color: $gray05;
  width: 7.2rem;
  height: 7.2rem;
  font-size: 1.3rem;
  background-color: $gray08;
  color: $gray03;
  border-radius: 3.6rem;
  line-height: 1.3rem;
}

.gridWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 90rem;
}

.submitButton {
  color: $white;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 0 0 0.8rem;

  &:hover,
  &:focus {
    color: $white;
  }
}

.modalCancelButton {
  margin: 0;
  border-color: $gray05;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  background-color: $gray05;
}