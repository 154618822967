@import "~component-library/dist/styles/constants.scss";

.photoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18.3rem, 1fr));
  column-gap: 0.2rem;
  row-gap: 2.8rem;
  margin-bottom: 2rem;

  .imgWrapper {
    position: relative;
    width: 100%;
    height: 16rem;
    margin-bottom: 1rem;

    &:hover,
    &:focus {
      .overlay {
        display: flex;
      }
    }

    .imgContainer {
      display: block;
      width: 100%;
      height: 13rem;
      background-color: $gray06;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.overlay {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 113, 206, 0.75);
  gap: 1.5rem;
}

.name {
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 600;
  color: $darkBlue11;
}

.count {
  color: $gray03;
  line-height: 1;
}

.pinIcon {
  width: 2rem;
  height: 2rem;
  color: $white;
}

.hoverIcon {
  cursor: pointer;
  padding: 0.5rem;
}
