@import "~component-library/dist/styles/constants.scss";

.statsTitle {
  margin-bottom: 1.2rem;
  color: $darkBlue10;
  font-size: 1.6rem;
  font-weight: 600;

  span {
    color: $darkBlue11;
    font-weight: 400;
    vertical-align: text-top;
  }
}

.statsRow {
  display: flex;
  margin-bottom: 3.5rem;
  flex-wrap: wrap;
  justify-content: space-between;

  &.dateRangeSelected {
    justify-content: flex-start;
  }
}

.multipleSelected {
  font-weight: 600;
  color: $darkBlue10;
}

.week {
  font-size: $med;
  padding: 0.2rem;
}

.customDropdown:before {
  position: absolute;
  top: -7px;
  left: 68px;
  border-left: 1px solid rgba(0, 0, 0, 0.175);
  border-top: 1px solid rgba(0, 0, 0, 0.175);
  background-color: #fff;
  padding: 6px;
  rotate: 45deg;
  z-index: -1;
  content: "";
}
