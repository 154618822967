@import "~component-library/dist/styles/constants.scss";

.bidItemTitle {
  margin-right: 1.5rem;
  color: $darkBlue10;
  font-size: 1.8rem;
  font-weight: 600;
}

.bidItemTasks {
  color: $gray03;
  font-size: 1.2rem;
}