@import "~component-library/dist/styles/constants.scss";

.toggleItem {
  padding: 0;
  background-color: transparent;
  border: none;
  width: 100%;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
}

.icon {
  margin-left: 0.8rem;
}