@import "~component-library/dist/styles/constants.scss";

.hour {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  min-width: 7.5rem;
}

.hourHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.temperature {
  font-weight: 600;
  font-size: 2rem;
  color: $darkBlue11;
}

.precipitation {
  color: $blue02;
  font-size: 1.25rem;
}

.time {
  font-size: 1.25rem;
  color: $darkBlue11;
}

.weather {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  overflow-x: auto;
  gap: 1rem;

  @media print {
    overflow-x: none;
    flex-wrap: wrap;
  }
}
