@import "~component-library/dist/styles/constants.scss";
.datePickerWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  width: 30rem !important;
  @media print {
    display: none;
  }
}

.datePickerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30rem !important;
  height: 5.6rem;
  border: 1px solid $gray05;
  padding-left: 1.6rem;

  span {
    color: $darkBlue10;
    font-size: 2.4rem;
    font-weight: 600;
  }

  > div {
    width: 100%;
  }

  &:hover,
  &:focus {
    border: 1px solid $gray05;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 17.5rem;

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.tasksContent {
  position: relative;
  min-height: 17.5rem;
}

.grid {
  max-width: 40rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17.5rem, 1fr));
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}

.section {
  width: 50%;
  margin-bottom: 2.5rem;
  padding: 1.6rem;

  &.hasBg {
    background-color: $gray09;
    border-radius: 0.4rem;
  }

  @media print {
    break-inside: avoid;
  }

  @media (min-width: 1200px) {
    width: 23%;
    min-width: 33.8rem;
    margin: 0 1.5rem;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media print {
    margin-right: 3.5rem;
  }

  h2 {
    color: $darkBlue10;
    font-size: 2rem;
    font-weight: 600;
  }
}

.remainingDays {
  font-size: 10rem;
  color: #045292;
  font-weight: 600;
  line-height: 0.5;

  &.negative {
    color: $red;
  }
}

.subtext {
  display: flex;
  flex-direction: column;
  color: $gray03;
  line-height: 1.7rem;
  margin-left: 1rem;
}

.sectionWrapper {
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    flex: 1;
    display: flex;
  }
}

.chartWrapper {
  margin-left: -1.8rem;
}