@import "~component-library/dist/styles/constants.scss";

.photo {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.photoBlur {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(2.5rem);
  opacity: 85%;
}

.albumContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $gray09;
}

.albumImage {
  min-width: 20vw;
  height: calc(100vh - 660px);
  object-fit: cover;
}

.albumTitle {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 2rem;
  color: $darkBlue10;
}

.albumPhotoCount {
  font-weight: 400;
  font-size: 1.8rem;
  color: $gray03;
}

.viewAlbum {
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
}

.navArrow {
  padding: 0.5rem;
  cursor: pointer;
}

.author {
  font-weight: 600;
  font-size: 2rem;
  color: $darkBlue10;
}

.timestamp {
  font-style: italic;
  font-weight: 400;
  font-size: 1.5rem;
  color: $gray03;
}

.pinIcon {
  width: 1.8rem;
  height: 1.8rem;
  color: $boomerangBlue;
}

.actionIcon {
  padding: 0.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.attach {
  font-weight: 400;
  font-size: 1.5rem;
  color: $gray03;
}

.selectorWrap {
  position: absolute;
  left: 4rem;
  bottom: 2rem;
}
