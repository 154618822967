@import "~component-library/dist/styles/constants.scss";

.userName {
  font-weight: 600;
  line-height: 1.8rem;
  color: $darkBlue10;
}

.createdDate {
  color: $gray03;
  font-style: italic;
}

.createdDateDetail {
  color: $gray03;
  font-size: 1.4rem;
  font-style: italic;
}
