@import "~component-library/dist/styles/constants.scss";

.inlineRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.innerWrapper {
  flex: 1;
  width: 100%;
  position: relative;
  margin-top: 2.5rem;
  padding: 0 2rem 12.5rem;
}

.headerToggle {
  color: $gray02;

  &:hover,
  &:focus {
    color: $gray02;
    text-decoration: none;
  }
}

.headerToggleActive {
  border-bottom: 2px solid $darkBlue11;
  border-radius: 0;
  color: $darkBlue10;
}

.editorTitle {
  color: $darkBlue10;
}