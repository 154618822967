@import "~component-library/dist/styles/constants.scss";

.photoGrid {
  display: grid;
  column-gap: 0.2rem;
  row-gap: 0.2rem;
  margin-bottom: 2rem;

  .imgWrapper {
    position: relative;
    width: 100%;
    background-color: $gray06;

    &:hover,
    &:focus {
      .overlay {
        display: flex;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.overlay {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 113, 206, 0.75);
  gap: 1.5rem;
}

.largeGrid {
  grid-template-columns: repeat(auto-fill, 18.9rem);

  .imgWrapper {
    height: 18.9rem;
  }
}

.mediumGrid {
  grid-template-columns: repeat(auto-fill, 15.7rem);

  .imgWrapper {
    height: 15.7rem;
  }
}

.smallGrid {
  grid-template-columns: repeat(auto-fill, 11.8rem);

  .imgWrapper {
    height: 11.8rem;
  }
}

.selectedIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 1.3rem;
  left: 1.3rem;
  background-color: $white;
  width: 3.3rem;
  height: 3.3rem;
  border: 1px solid $gray05;
  border-radius: 50%;

  &.active {
    background-color: $boomerangBlue;
    border: 1px solid $white;
  }
}

.selectedOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  opacity: 0.2;
}

.pinIcon {
  width: 2rem;
  height: 2rem;
  color: $white;
}

.hoverIcon {
  cursor: pointer;
  padding: 0.5rem;
}
