@import "~component-library/dist/styles/constants.scss";


.photosGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 8rem);
  column-gap: 0.8rem;
  row-gap: 0.8rem;
  margin-bottom: 1rem;
}

.photoWrapper {
  position: relative;
  width: 8rem;
  height: 8rem;
  padding: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4rem;
  }

  &:hover,
  &:focus {
    .overlay {
      display: flex;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: $white;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.addBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid $gray05;
  width: 8rem;
  height: 8rem;
  border-radius: 0.3rem;

  &:hover,
  &:focus {
    border: 1px solid $gray05;
  }
}