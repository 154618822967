@import "~component-library/dist/styles/constants.scss";

.sectionTitle {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-size: 2rem;
  color: $darkBlue11;
}

.pinIcon {
  width: 2rem;
  height: 2rem;
  color: $darkBlue11;
}
