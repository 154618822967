@import "~component-library/dist/styles/constants.scss";
.dailyLogDatePicker {
  align-items: center;
  width: 20rem !important;
  height: 5.6rem;
  border-radius: 0.3rem;
  border: 1px solid $gray05;
  background-color: $white;

  &:hover,
  &:focus {
    background-color: $white !important;
  }

  span {
    font-size: 2.4rem;
    font-weight: 600;
    color: $darkBlue10
  }

  svg {
    color: $gray03;
  }
}

.datePickerWrapper {
  display: flex;
  align-items: center;
  width: 30rem !important;
  margin-right: 3rem;
}

.datePickerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30rem !important;
  height: 5.6rem;
  border: 1px solid $gray05;
  padding-left: 1.6rem;

  span {
    color: $darkBlue10;
    font-size: 2.4rem;
    font-weight: 600;
  }

  > div {
    width: 100%;
  }

  &:hover,
  &:focus {
    border: 1px solid $gray05;
  }
}