@import "~component-library/dist/styles/constants.scss";
.firstColumn {
  width: 20.5rem;
}

.taskBody {
  border: 1px solid $gray06;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius:  0.4rem;
}

.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.6rem 1.7rem;
  background-color: $gray09;
}

.inputWrapper {
  color: $gray03;
  font-weight: bold;
}

.input {
  max-width: 10rem;
}

.disabledInput {
  background-color: $gray05;
}

.timeEntries {
  padding: 1.5rem 2.2rem 0;
}

.noEntries {
  margin-bottom: 1rem;
  font-weight: bold;
  border-radius: 0.8rem;
  padding: 1rem 2.1rem;
  background-color: $gray09;
}