@import "~component-library/dist/styles/constants.scss";

.table-heading {
  font-size: 1.6rem;
  color: $dark-text;
}

.table-count {
  color: $light-text;
  font-size: 1.2rem;
}

.priority {
  text-transform: uppercase;
  line-height: 1.6rem;
  border-radius: 0.8rem;
  padding: 0 0.8rem;
  display: inline-block;
  color: #fff;
  font-size: 1.2rem;
}
.med {
  background-color: $yellowOrange;
}
.high {
  background-color: $redOrange;
}
.low {
  background-color: $paleGreen;
}

.overdue {
  color: $redOrange;
  font-weight: bold;
}
