@import "~component-library/dist/styles/constants.scss";

.modalFieldTitle {
  width: 12rem;
  text-align: right;
  color: $gray03;
}

.header {
  padding: 2rem 2.4rem;
  background-color: $gray09;
}

.title {
  margin-left: 0.8rem;
  font-weight: 600;
  font-size: 2rem;
  color: $darkBlue11;
}

.modalCancelButton {
  margin: 0;
  border-color: $gray05;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  background-color: $gray05;
}

.body {
  padding: 2rem 2.4rem;
}

.footer {
  border: none;
  background-color: $gray09;
  padding: 1.6rem 2.5rem;
  margin-top: 6rem;
}

.submitButton {
  font-weight: 600;
  margin: 0 0 0 0.8rem;
}

.fileBorder {
  display: flex;
  height: 4.8rem;
  padding: 2.4rem 1.5rem;
  align-items: center;
  border: 1px solid $gray05;
}

.modalIcons {
  margin-right: 0.8rem;
}

.currentTitleWrapper {
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  padding: 0.8rem;
  background-color: $gray09;
}

.currentTitle {
  font-size: 1.6rem;
  margin-left: 0.8rem;
}