@import "~component-library/dist/styles/constants.scss";

.todoToggler {
  width: 100%;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $gray05;
  border-radius: 0.4rem;
  margin-left: 0.8rem;
  padding-right: 1rem;
  color: $gray03;
}

.wrappedInput {
  border: none;
  margin-right: 1rem;
}
