@import "~component-library/dist/styles/constants.scss";

.datePickerWrapper {
  width: 15rem;
}

.dropdownWrapper {
  width: 56rem;
}

.formWrapper {
  background-color: $lightGray;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60rem;
  padding: 2rem;
}

.weatherGridHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  margin-top: 1rem;
}

.projectTtileText {
  color: $darkBlue11;
  margin-bottom: 0rem;
  font-weight: 600;
}

.headerText {
  color: $darkBlue11;
}

.searchText {
  color: $gray02;
  margin-bottom: 1rem;
}

.datePicker {
  width: 15rem;
  background-color: $white;
  color: $gray02;
  border: 1px solid $gray05;
  align-items: center;
  font-size: 1.4rem;
  &:hover {
    background-color: $white;
    color: $gray02;
    border: 1px solid $gray05;
  }
}