@import "~component-library/dist/styles/constants.scss";

.takeoff {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  border-radius: 0.8rem;
  padding: 1.5rem;
  background-color: $gray09;
}

.code {
  color: $gray02;
}

.name {
  margin-left: 2rem;
  font-weight: 600;
  font-size: 1.8rem;
  color: $boomerangBlue;
}

.qty {
  margin-right: 0.8rem;
  font-weight: 600;
  font-size: 2rem;
  color: $darkBlue10;
}

.uom {
  font-size: 1.2rem;
  color: $grayTableHeader;
  text-transform: uppercase;
}