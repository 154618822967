@import "~component-library/dist/styles/constants.scss";

.task {
  margin-bottom: 1.5rem;
}

.firstColumn {
  width: 20.5rem;
}

.taskBody {
  border: 1px solid $gray06;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius:  0.4rem;
}

.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.6rem 1.7rem;
  background-color: $gray09;
}

.inputWrapper {
  color: $gray03;
  font-weight: bold;
}

.input {
  max-width: 10rem;
}

.dropActive {
  opacity: 0.5
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-color: $boomerangBlue;
  z-index: 99;
  border-radius: 0.4rem;
}

.asterisk {
  color: $red;
}

.invalidInput {
  border: 2px solid $red;
}