@import "~component-library/dist/styles/constants.scss";

.taskName {
  font-size: 1.6rem;
  font-weight: bold;
  color: $boomerangBlue;
  width: 34.8rem;
  max-width: 34.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name {
  font-size: 1.6rem;
  font-weight: bold;
  color: $gray02;
  max-width: 22.5rem;
  width: 22.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.task {
  margin-top: 1rem;
  border-radius: 0.4rem;
}

.select {
  max-width: 27.5rem;
}

.close {
  background-color: $gray08;
}

.noTimesheet {
  margin-top: 1rem;
  border-radius: 0.4rem;
  background-color: $gray08;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;

  .noTimesheetText {
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: bold;
    color: $gray02;
  }

  .addCrewButton {
    font-size: 1.6rem;
    background-color: #fff;
    border-color: $gray05;
  }
}

.actions {
  margin-top: 1.5rem;
  margin-top: 1rem;

  .actionButton  {
    padding: 0.4rem 0.4rem;
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-start;
    gap: 2.5rem;
    font-size: 1.6rem;
  }
}
.driveTimeModal {
  margin: 0;
  padding: 0;
  *[class~=modal-content] {
    margin: 0;
    padding: 0;
  }
  .driveTimeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1rem;
    font-size: 1.6rem;
    font-weight: bold;
    color: $gray02;
    background-color: $gray09;
  }
  .driveTimeFooter {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    background-color: $gray09;
    padding: 2.5rem 1.5rem;
  
    button {
      padding: 1rem 1.5rem;
    }
  }
}

.question {
  text-align: center;
  em {
    font-style: normal;
    color: $yellowOrange;
  }
}

.questionContent {
  font-size: 1.8rem;
  .questionTitle {
    font-size: 2.36rem;
    font-weight: bold;
    color: $gray02;
    margin-bottom: 1rem;
    text-align: center;
  }
  .questionButtons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    font-size: 1.6rem;
    font-weight: bold;
    color: $gray02;
    margin-top: 1.5rem;

    button {
      padding: 1rem 2.5rem;
    }
  }
}

.perDiem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1rem 0.7rem 2rem;
  width: 25rem;
  background-color: $blue06;
  border-radius: 2.5rem;
  
  .perDiemLabel {
    font-size: 1.6rem;
    color: $iGiveUpGray;
  }
}

.late {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1rem 0.7rem 2rem;
  width: 15rem;
  border-radius: 2.5rem;
  background-color: #FFF5E5;

  .perDiemLabel {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    color: $iGiveUpGray;
    margin-right: 0.8rem;
  }
}

.absent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1rem 0.7rem 2rem;
  width: 15rem;
  border-radius: 2.5rem;
  background-color: #FFEAEA;

  .perDiemLabel {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    color: #434343;
    margin-right: 0.8rem;
  }
}

.totalHoursWrapper {
  margin-left: 0.8rem;
  width: 14rem;
}

.unassignedWrapper {
  margin: 0 0.8rem;
  width: 17.2rem;
}

.btnLabel {
  color: $gray03;
  font-size: 1.6rem;
}

.btnLabel:hover {
  color: $darkBlue11;
  text-decoration: none;
  cursor: pointer;
}

.interfaceIconWrapper {
  display: flex;
  justify-content: center;
  width: 2rem;
}

.value {
  color: $boomerangBlue;
  font-weight: 600;
  margin-right: 0.8rem;
}

.icon {
  margin-right: 0.8rem;
}