@import "~component-library/dist/styles/constants.scss";

.historyHeader {
  span {
    color: $gray03;
    font-size: 1.6rem;
  }
}

.eventBorder {
  border-left: 2px solid $gray09;
  padding-left: 1.6rem;
}

.historyDateTime {
  color: $gray02;
  font-size: 1.6rem;
  font-weight: 600;
}

.historyDateWidth {
  width: 10rem;
}

.event {
  font-style: italic;
  color: $gray03;
}