@import "~component-library/dist/styles/constants.scss";

.checkWrapper {
  width: 22px;
  height: 22px;
  border: 1px solid $gray05;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  margin-left: .2rem;
}

.linkLight {
  color: $boomerangBlue;
  cursor: pointer;
}