@import "~component-library/dist/styles/constants.scss";

.label {
  color: $gray03;
  font-size: 1.6rem;
}

.label:hover {
  color: $darkBlue11;
  text-decoration: none;
  cursor: pointer;
}