// Override default variables before the import
@import "~component-library/dist/styles/constants.scss";
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

@import "~component-library/dist/styles/forms.scss";
@import "~component-library/dist/styles/sortableTable.scss";
@import "~component-library/dist/styles/quotes.scss";
@import "~component-library/dist/styles/siteNav.scss";
@import "~component-library/dist/styles/datePicker.scss";
@import "~component-library/dist/styles/layout.scss";
@import "~component-library/dist/styles/print.scss";
@import "~component-library/dist/styles/agGridStyles.scss";

html {
  font-size: 62.5%;
}

body {
  color: $dark-text;
  font-size: 1.6rem;
  font-family: "Source Sans Pro", sans-serif;

  &.modal-open {
    position: fixed;
    width: 100%;
    overflow: hidden !important;
  }
}

.white-space-pre {
  white-space: pre-line;
}

.modal-sm {
  width: 100%;
  max-width: 40rem;
}

.modal-lg {
  width: 100%;
  max-width: 70rem;
}

.btn {
  font-size: 1.2rem;
  font-weight: 400;
}
.react-datepicker__input-container {
  .btn {
    font-size: 1.6rem;
    align-items: center;
  }
}
.page-title {
  color: $dark-text;
  font-size: 2.36rem;
}

.page-subtitle {
  font-size: 1.6rem;
  color: $light-text;
}

.attachment {
  word-break: break-all;
}
.form-control-plaintext {
  padding: 0.6rem;
}
.submittal-sidebar {
  @include media-breakpoint-up(lg) {
    border-left: 1px solid #dee2e6;
  }
  .form-control {
    width: auto;
    display: inline;
  }
  @include media-breakpoint-down(md) {
    h6,
    .h6 {
      text-align: left;
    }
    .react-datepicker-wrapper {
      display: block;
    }
  }
}
.form-group .react-datepicker-wrapper {
  display: block;
}

.btn.btn-tertiary {
  color: #fff;
}

.btn.btn-orange {
  background-color: $yellowOrange;
  border-color: $yellowOrange;
  color: $white;
}

.btn.btn-light-gray {
  background-color: #d4d4d4;
  border-color: #d4d4d4;
  color: $white;
}

.btn.btn-lg {
  font-size: 1.6rem;
}

.input-search {
  position: relative;
  .input-group-append {
    position: absolute;
    right: 0.6rem;
    top: 0.8rem;
  }
}

.status-filter {
  button {
    border-radius: 0 !important;
    cursor: pointer;
  }

  .active {
    color: $dark-text;
  }
}

@include media-breakpoint-down(sm) {
  .sortable-table {
    max-width: 100%;
  }
}

.contact-name {
  padding-bottom: none;
  margin-bottom: 0;
  color: $gray02;
}

.contacts .rounded-circle {
  border-style: dashed;
  width: 7.2rem;
  min-height: 7.2rem;
  padding: 0.9rem;
  font-size: 1.3rem;
  background-color: $gray08;
}

@include media-breakpoint-up(lg) {
  .contacts {
    .rounded-circle {
      border-style: dashed;
      border-radius: 1rem !important;
      width: 100%;
      min-width: 25rem;
      min-height: 20rem;
      height: 100%;
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }
  }
  .plans input.form-control {
    max-width: 100%;
    width: 20rem;
  }

  .submittal-content {
    border-right: 1px solid #dee2e6;
  }

  .sortable-table {
    overflow-x: unset;
  }
}

.table-striped {
  td,
  th {
    border-color: #dee2e6;
  }
}

.light-text {
  color: $light-text;
}
.dark-text {
  color: $dark-text;
}

.gray03 {
  color: $gray03;
}

.blue-text {
  color: $boomerangBlue;
}
.modal-header {
  border-bottom: 0 none;
}
.modal-content {
  &::not("fullWidth") {
    padding: 0 2rem 2rem;
  }
}

.modal-content.popup-content {
  padding: 2.5rem;
  background-color: $boomerangBlue;
  color: $white;
  border-radius: 10rem;
  text-align: center;
  box-shadow: 0px 0px 2.2rem 0px rgba(0, 0, 0, 0.25);
  font-size: 2rem;
  font-weight: semi-bold;
}
.modal-open {
  position: static !important;
}
.file-history {
  .modal-content {
    padding: 0 1rem 2rem;
  }
}

.nine {
  font-size: $nine;
}

.sm {
  font-size: $sm;
}

.thirteen {
  font-size: $thirteen;
}

.sm-med {
  font-size: $sm-med;
}

.med {
  font-size: $med;
}

.lg {
  font-size: $lg;
}

.xl {
  font-size: $xl;
}

.xxl {
  font-size: $xxl;
}

.xxxl {
  font-size: $xxl;
}

.todo-item {
  background-color: $gray09;
  min-height: 2.4rem;
}

.todo {
  cursor: pointer;
  color: #232323;
  &.complete {
    background-color: #a5e46f;
    cursor: default;
  }
}

label {
  font-size: 1.4rem;
}

.bid-label {
  font-size: 1.4rem;
  color: #7b7b7b;
  font-weight: bold;
}

.subLabel {
  line-height: 1;
  margin-bottom: 0.5rem;
  margin-top: -0.5rem;
}

textarea {
  height: 10.4rem;
  border-radius: 0.4rem;
  border: 1px solid #c4c4c4;
}

.option-active {
  background-color: $trBackground;
}

.dropdown-option {
  border-bottom: 1px solid $rule-light;
  border-top: 0 none;
}

.modal-content {
  &::not("fullWidthContent") {
    border-top: 0.8rem solid $boomerangBlue;
  }
}

.fullWidthContent .modal-body {
  padding: 0;
}

.modal-header {
  h4 {
    font-size: 1.6rem;
  }
}
.fullWidthContent .close {
  color: $white;
}

.confirmation-header {
  color: $gray02;
}
.confirmation-text {
  font-weight: 600;
  color: $gray02;
}

.contact-pill {
  background-color: #0071ce10;
  border-radius: 2.5rem;
  padding: 0.3rem 0.9rem 0.3rem 0.7rem;
  margin: 0.5rem 0 0.5rem;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 1.1rem;

  &.archived {
    color: $gray03;
    background-color: $gray06;
  }
}

.circle-file-dropzone .dropzone {
  border-radius: 100%;
  height: 7.2rem;
  width: 7.2rem;
  padding: $sm;
  border: 1px dashed #797c84;
  background-color: $gray08;
  cursor: pointer;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  p {
    font-size: $thirteen;
    color: #8f8f8f;
  }
}

.circle-file-dropzone .dropzone:hover {
  color: #fff;
  background-color: #797c84;
  border: 1px dashed #797c84;

  p {
    color: #fff;
  }
}

.milestones-header {
  display: flex;
}

.milestones {
  width: 100%;
  display: inline-block;

  .milestone {
    margin: 0.5rem 0px 1rem;
    background-color: $lightGray;
    border-radius: 1rem;
  }

  .milestone > td {
    display: inline-block;
    color: #717171;
    border-radius: 1.5rem;
    padding: 0.3rem 1rem;
  }
}

.tracker {
  background-color: #f0f0f0;
  padding: 0.7rem;
  border-radius: 2.5rem;
  margin: 0.5rem 0;
  .counter {
    background-color: #ffffff;
    width: 100%;
    border-radius: 1.5rem;
    margin: 0 0.5rem;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
    &.in-progress {
      background-color: #ffa400;
    }
    &.near-complete {
      background-color: #f0c808;
    }
  }
  &.finished {
    .counter {
      background-color: #a5e46f;
    }
  }
}
@include media-breakpoint-up(lg) {
  .tracker-wrap {
    max-width: calc(100% - 2.5rem);
  }
}

.image {
  position: relative;
  border: 1px solid $borderGray;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  margin-bottom: 0.5rem;
}

.project-documents {
  .image {
    margin: 0 auto 0.5rem;
  }
}

.border-top {
  border-top: 1px solid $rule-light;
}

.modal-close {
  background-color: #f0f0f0;
  border: #f0f0f0;
  padding: 1rem;
  border-radius: 0.8rem;
  &:hover {
    background-color: #797c84;
  }
  &:active {
    background-color: #f0f0f0 !important;
  }
  &:focus {
    background-color: #f0f0f0;
  }
}

.geofence-modal-wrap {
  .close {
    color: white;
    font-weight: 400;
  }
  .modal-content {
    border-top: 0;
    padding: 0;
  }
}

.menu-link-icon {
  padding: 0.8rem 0.6rem;
  margin: 0 0.8rem;
  svg {
    color: $blue03;
  }
  svg:hover {
    color: #fff;
  }
}

.app-menu {
  position: relative;
  z-index: 1000;
  cursor: pointer;

  .menu-toggle {
    background-color: #f0f0f0;
    padding: 1rem;
    border-radius: 0.8rem;
    position: relative;
  }

  .menu-toggle-icon {
    padding: 0.8rem 0.6rem;
    svg {
      color: $blue03;
    }
    svg:hover {
      color: #fff;
    }
  }

  .menu-content {
    position: absolute;
    width: 100vw;
    max-width: 32rem;
    text-align: left;
    right: -0.2rem;
    background-color: #fff;
    border-radius: 0.2rem;
    padding: 0.5rem;
    margin: 1rem 0;
    border: none;
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.25);

    &::after {
      top: -0.7rem;
      content: "";
      position: absolute;
      background-color: #fff;
      right: 1.1rem;
      height: 1.4rem;
      width: 1.4rem;
      transform: rotate(45deg);
    }

    &::before {
      top: -0.7rem;
      content: "";
      position: absolute;
      background-color: #fff;
      right: 1.1rem;
      height: 1.4rem;
      width: 1.4rem;
      transform: rotate(45deg);
      box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.25);
      z-index: -1;
    }

    .btn {
      text-align: left;
      font-size: 1.4rem;
    }
  }
}

.checklist-row {
  border-radius: 0.8rem;
  padding: 1rem 2rem;
  &:nth-of-type(odd) {
    background-color: $lightGray;
  }
  dd {
    margin-bottom: 0;
    text-transform: uppercase;
  }
}

@media (min-width: 768px) {
  .table-responsive {
    overflow-x: unset;
    table {
      table-layout: fixed;
    }
  }
  .project-documents {
    .carousel-inner .active,
    .carousel-inner .active + .carousel-item,
    .carousel-inner .active + .carousel-item + .carousel-item,
    .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
      display: block;
      margin-right: 0;
    }

    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
    .carousel-inner
      .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
      + .carousel-item
      + .carousel-item,
    .carousel-inner
      .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
      + .carousel-item
      + .carousel-item
      + .carousel-item {
      transition: none;
    }

    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
      position: relative;
      transform: translate3d(0, 0, 0);
    }

    .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: absolute;
      top: 0;
      right: -25%;
      z-index: -1;
      display: block;
      visibility: visible;
    }

    .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .carousel-item-next.carousel-item-left + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
    .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }

    .carousel-inner .carousel-item-prev.carousel-item-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      visibility: visible;
    }

    .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .carousel-item-prev.carousel-item-right + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item,
    .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
      visibility: visible;
    }
  }
}

.file-embed {
  width: 100%;
  border: 0 none;
  height: calc(100vh - 9.6rem);
}

.border-left-lg {
  @include media-breakpoint-up(lg) {
    border-left: 1px solid #dee2e6 !important;
  }
}

.ps-4-lg {
  @include media-breakpoint-up(lg) {
    padding-left: 2.4rem !important;
  }
}

.text-end-lg {
  @include media-breakpoint-up(lg) {
    text-align: right !important;
  }
}

.sticky {
  position: fixed;
  z-index: 2;
}

.submenu-active {
  display: flex !important;
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 144rem;
  padding-left: 2.6rem;
  padding-right: 2.6rem;
}

.text-break {
  word-break: break-word;
}

.error {
  color: $red;
  font-size: 1.4rem;
}

.wrapper {
  max-width: 100%;
  width: 100%;
}

.site-wrapper {
  transition: all 200ms ease-out;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
}

@media (max-width: 1200px) {
  .navigation-wrapper {
    overflow: hidden;
    position: fixed;
    width: 100vw;
    height: 100vh;
  }
  .site-wrapper.opened-menu {
    position: absolute;
    transform: translateX(28rem);
  }
}

.contacts {
  display: inline-flex;
  overflow-x: auto;
  max-width: 100%;

  .contact {
    min-width: 22.5rem;
    margin-right: 2rem;
    position: relative;
  }

  @media (min-width: 992px) {
    display: flex;
    flex-flow: wrap;
    .contact {
      width: 45%;
    }
  }
  @media (min-width: 1200px) {
    .contact {
      width: 25rem;
    }
  }
}

.overflow-visible {
  overflow: visible !important;
}

.justify-content-center.page-titles {
  a,
  .page-subtitle,
  .page-title {
    text-align: center;
    display: block;
  }
}
.submittal-page {
  .justify-content-center.page-titles {
    @include media-breakpoint-down(xl) {
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.opened-menu {
  @include media-breakpoint-down(lg) {
    .click-block {
      height: 100vh;
      width: 100%;
      position: fixed;
      z-index: 999;
    }
  }
}

.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12 {
  position: relative;
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.col-xxl-1 {
  flex: 0 0 8.33%;
  max-width: 8.33%;
}
.col-xxl-2 {
  flex: 0 0 16.67%;
  max-width: 16.67%;
}
.col-xxl-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-xxl-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.col-xxl-5 {
  flex: 0 0 41.67%;
  max-width: 41.67%;
}
.col-xxl-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-xxl-7 {
  flex: 0 0 58.33%;
  max-width: 58.33%;
}
.col-xxl-8 {
  flex: 0 0 66.67%;
  max-width: 66.67%;
}
.col-xxl-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-xxl-10 {
  flex: 0 0 58.33%;
  max-width: 58.33%;
}
.col-xxl-11 {
  flex: 0 0 91.67%;
  max-width: 91.67%;
}
.col-xxl-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.d-xxl-none {
  display: none !important;
}
.d-xxl-block {
  display: block !important;
}
.text-xxl-center {
  text-align: center !important;
}

.phone {
  background-color: $blueTenPercent;
  font-size: 1.1rem;
  color: $boomerangBlue;
  border-radius: 0.8rem;
  height: 1.6rem;
  padding: 0 0.6rem;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }

  &.archived {
    color: $gray03;
    background-color: $gray06;
  }
}

.phoneIcon {
  margin-right: 0.2rem;
}

.pillItem {
  background-color: RGBA(0, 113, 206, 0.1);
  border-radius: 1.6rem;
  color: $boomerangBlue;
  font-weight: 700;
  padding-right: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.removePillItemButtons {
  border-radius: 1.6rem;
  padding-right: 0.4rem !important;
}

.default {
  cursor: default !important;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 99;
  top: 0;
  left: 0;
}

.topLoaderWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 99;
}

.u {
  text-decoration: underline;
}

.phase-badge {
  max-width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}

.form-control-submit {
  align-self: center;
  margin-top: 0.8rem;
  color: #fff;
}

.rcs-custom-scrollbar {
  background-color: transparent;
}
.rcs-inner-handle {
  background-color: $blue03;
  width: 0.4rem;
}

.link {
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: $boomerangBlue;
}

.noLink {
  cursor: default;
}

button:disabled:not(.btn-link) {
  border: 1px solid $gray04 !important;
  background-color: $gray04 !important;
}

.overflow-visible {
  overflow: visible;
}

#gantt {
  width: 100%;
  flex-grow: 0;
  height: calc(100vh - 8.8rem);
}

.dx-gantt-collapsable-row {
  td {
    font-weight: 600;
  }
}

.dx-viewport {
  align-self: stretch;
  width: 100%;
  display: flex;
  height: calc(100vh - 8.8rem);
}

.strike {
  text-decoration: line-through;
}

.form-section-header {
  font-size: 1.8rem;
  font-weight: 600;
}

.large-form-divider {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.large-form-section {
  border-bottom: 1px solid $gray06;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.large-form-submit {
  height: 3rem;
  min-width: 10rem;
  font-weight: 600;
  font-size: 1rem;
  position: static;
  margin-bottom: 4rem;
  margin-top: 2rem;
}

@include media-breakpoint-up(lg) {
  .fixed-sidebar {
    position: sticky;
    top: 0;
    padding-bottom: 8rem;
    .fixed-sidebar {
      max-width: calc(100% - 3.5rem);
      padding-top: 2rem;
    }
  }
  .large-form-submit {
    position: fixed;
    bottom: 4rem;
    margin-bottom: 0;
  }
}

.align-with-label {
  padding-top: 2.4rem;
}

.range-dash {
  position: absolute;
  width: 2rem;
  height: 2rem;
  left: -1rem;
  display: block;
  text-align: center;
}

.progress-label {
  font-size: 1.4rem;
  color: $gray03;
}

.semibold {
  font-weight: 600 !important;
}

.detail-section-toggler {
  font-weight: 600;
  font-size: 1.8rem;
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
}

.document-group {
  display: flex;
  max-width: 48%;
  flex-direction: column;
}

.document-group-header {
  font-size: 1.4rem;
  color: $gray03;
  text-transform: uppercase;
}

.addenda-count {
  background-color: $yellowOrange;
  color: #fff;
  display: block;
  height: 1.6rem;
  border-radius: 0.8rem;
  padding: 0 0.3rem;
  min-width: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: normal;
  margin-left: 0.8rem;
}

@include media-breakpoint-up(md) {
  .unpad-section {
    margin-left: -0.9rem;
    margin-right: -2.5rem;
  }
}

.pill {
  font-size: 0.9rem;
  color: $white;
  border-radius: 0.8rem;
  height: 1.6rem;
  background-color: $boomerangBlue;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.popover-left {
  .arrow {
    transform: translate3d(1.2rem, 0, 0) !important;
  }
}

.inline-form {
  .form-group {
    margin-bottom: 0;
  }
}

.errorMsg {
  word-wrap: break-word;
}

.team-select {
  .todo-select__indicators {
    .todo-select__clear-indicator {
      display: none;
    }
  }

  .todo-select__value-container {
    .todo-select__multi-value__label {
      font-size: 1.3rem;
      color: $gray02;
    }

    .todo-select__multi-value {
      background-color: transparent;
    }
  }

  .todo-select__control {
    background-color: $gray06;
  }
}

.text-limiter {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 2rem);
}

.ag-cell {
  overflow: hidden;
}

.ag-cell-wrapper {
  width: 100%;
  overflow: hidden;
}

.operations-list,
.calendar-wrap {
  .ag-group-value {
    flex: 1;
    width: 100%;
  }

  .ag-cell-wrapper.ag-cell-expandable.ag-row-group {
    align-items: center;
  }
}

.rechart-wrapper {
  .recharts-legend-item-text {
    color: $gray02 !important;
  }
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

.btn-reset {
  background-color: transparent;
  border-color: transparent;
  font-size: 1.6rem;
  padding: 0;
  margin: 0;
  &:active,
  &:hover,
  &:focus {
    background-color: transparent;
    border-color: $gray05;
    box-shadow: none;
    color: $gray03;
  }
}

.ag-row-group {
  font-size: 1.6rem !important;
}

.activity-log-grid {
  .ag-row-group {
    font-weight: 600;
  }
}

.detailGroupRow {
  font-weight: 400 !important;
}

.calendarWrap {
  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    display: none;
  }
}

.close {
  font-size: 3rem;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 600;
}

.btn,
.btn-link,
.headerTab {
  text-decoration: none !important;
}

.btn:hover,
.btn-link:hover {
  text-decoration: none !important;
}

.accordion-item {
  border: none;
}

.accordion-body {
  padding: 0;

  & > .accordion-item {
    margin-top: 1rem;
  }
}

.accordion-button {
  background-color: $gray09;

  &:not(.collapsed) {
    background-color: $gray09;
  }
}

.accordion-button {
  height: 4.8rem;
  border-radius: 0.8rem !important;
}

.progress {
  height: 1.6rem;
  border-radius: 1.6rem;
  background-color: transparent;
}

.hiddenPrint,
.hidePrint {
  @media print {
    display: none;
  }
}

.showPrint {
  display: none;
  @media print {
    display: block;
  }
}

.react-time-picker {
  border: 1px solid #d3d3d3;
  border-radius: 0.4rem;

  .react-time-picker__wrapper {
    border: none;
  }

  input {
    font-size: 1.6rem;
  }

  select {
    appearance: none;
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
  }

  /* For IE10 */
  select::-ms-expand {
    display: none;
  }
}

div .react-datepicker-wrapper {
  display: block;
  .react-datepicker__close-icon {
    &:after {
      background-color: transparent;
      color: $boomerangBlue;
      border: 0.175rem solid $boomerangBlue;
      width: 2rem;
      font-weight: bold;
    }
  }
}

.modal-header {
  justify-content: space-between;
}
