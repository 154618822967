@import "~component-library/dist/styles/constants.scss";

.tasksHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  text-decoration: none !important;
}

.error {
  max-width: 30rem;
  word-break: break-all;
}

.title {
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: $darkBlue10;
  font-weight: 600;

  span {
    font-weight: 400;
  }
}

.link {
  color: $boomerangBlue;
  text-decoration: none;
}

.noTasks {
  font-weight: 600;
}

.headerContent {
  margin-right: 1.5rem;
}