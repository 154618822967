@import "~component-library/dist/styles/constants.scss";

.notesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  text-decoration: none !important;
}

.title {
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: $darkBlue10;
  font-weight: 600;

  span {
    font-weight: 400;
  }
}

.noteWrapper {
  padding: 0.8rem 1.5rem;
  margin-bottom: 0.8rem;
  border: 1px solid $gray06;
}

.name {
  color: $gray02;
}

.date {
  color: $gray03;
}

.noteTitle {
  font-weight: 600;
  font-size: 1.8rem;
  color: $boomerangBlue;
}

.commentDetails {
  margin-top: 1rem;
  border-radius: 0.4rem;
  padding: 0.9rem 1.6rem;
  color: $gray03;
  background-color: $gray09;
}

.photos {
  display: flex;
  margin-left: 1.6rem;
  min-width: 14.5rem;

  &.large {
    min-width: 15rem;
  }
}

.firstImage {
  width: 9.6rem;
}

.smallImage {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  width: 4.4rem;
}

.overlay {
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.3);
  font-size: 2.4rem;
  font-weight: 600;
}

.link {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1.6rem;
}

.noNotes {
  font-weight: 600;
}

.headerContent {
  margin-right: 1.5rem;
}